import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TypeElementRel, TypeValeurResultat, TypeValeurResultatWithElements, ValeurReference, TypeElement, AffichageValeurReference } from 'src/app/interfaces/adela/resultat-analyse';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AdelaFacadeService } from 'src/app/services/adela/adela-facade.service';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { NgIf, NgFor } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-composante',
    templateUrl: './composante.component.html',
    styleUrls: ['./composante.component.scss'],
    standalone: true,
    imports: [NgIf, MatCardModule, ReactiveFormsModule, MatGridListModule, NgFor, MatSlideToggleModule, MatButtonModule,
              MatTabsModule, MatTableModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule]
})
export class ComposanteComponent implements OnInit {
typeValeurResultatElements : TypeValeurResultatWithElements[]=[];
typeValeurElements$!      : Observable<TypeValeurResultat[]>
typeValeurElements        : TypeValeurResultat[]=[];
typeValeurElementForm!    : FormGroup;
valeursReferencesForm!    : FormGroup;
valeursReferences         = new MatTableDataSource<AffichageValeurReference>();
displayedColumnsValeursReference   : string[] = ['TYPEVALEUR',
                                                  this.apiAdelaService.CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE,
                                                  this.apiAdelaService.CONST_PRODUIT_REFERENCE_POUDRE_BLEUE,
                                                  this.apiAdelaService.CONST_PRODUIT_REFERENCE_FARINE_BLE,
                                                  this.apiAdelaService.CONST_PRODUIT_REFERENCE_TALC];
afficherValeursReferences : boolean = false;
waitingSave               : boolean = false;

elementArray():FormArray{
  return this.typeValeurElementForm.get(['elementArray']) as FormArray
}

typeElement(i:number):FormArray{
  return this.elementArray().at(i).get(['typeElement']) as FormArray
}

checked(i:number,j:number):FormControl{
  return this.typeElement(i).at(j).get(['checked']) as FormControl
}

  constructor(  private apiAdelaFacade  : AdelaFacadeService
              , private fb              : FormBuilder
              , public apiAdelaService  : ApiAdelaService
              , private toast            : PersoSnackbarService) { }

  ngOnInit(): void {
   
    this.apiAdelaFacade.typesValeurResultatWithElements$.subscribe(
      (typeValeurResultatElement:TypeValeurResultatWithElements[])=>{
        
        this.typeValeurElementForm = this.generateFormGroup(typeValeurResultatElement)
      }
    )

    this.getValeurReferences();
  }

  enregistrer(){
    this.apiAdelaFacade.enregistreLiens(this.typeValeurElementForm)
  }

  enregistrerValeursReferences(){
    let tabValeurReference:ValeurReference[] = [];

    this.waitingSave = true;

    for (let index = 0; index < this.valeursReferences.data.length; index++) {
      if (this.valeursReferences.data[index].farineBle.ancienneValeur != this.valeursReferences.data[index].farineBle.nouvelleValeur) {
        tabValeurReference.push(this.valeursReferences.data[index].farineBle);  
      }

      if (this.valeursReferences.data[index].poudreBlanche.ancienneValeur != this.valeursReferences.data[index].poudreBlanche.nouvelleValeur) {
        tabValeurReference.push(this.valeursReferences.data[index].poudreBlanche);  
      }

      if (this.valeursReferences.data[index].poudreBleue.ancienneValeur != this.valeursReferences.data[index].poudreBleue.nouvelleValeur) {
        tabValeurReference.push(this.valeursReferences.data[index].poudreBleue);  
      }

      if (this.valeursReferences.data[index].talc.ancienneValeur != this.valeursReferences.data[index].talc.nouvelleValeur) {
        tabValeurReference.push(this.valeursReferences.data[index].talc);  
      }
    }

    if (tabValeurReference.length > 0) {
      this.apiAdelaService.putValeurReferences(tabValeurReference).subscribe(
        data => {  
            this.toast.showInfo("Enregistrement effectué");
            
            this.getValeurReferences();
            
            this.waitingSave = false;
          },
          (err) => {
            this.toast.showError("Echec de l'enregistrement");
            console.error('/!\\ error enregistrerValeursReferences: '+err);
            this.waitingSave = false;
        }
      )
    } else {
      this.waitingSave = false;
    }
  }

  generateFormGroup(data:TypeValeurResultatWithElements[]):FormGroup{
    return this.fb.group({elementArray:this.fb.array(data.map((element)=>this.generateFormControl(element)))})
  }

  generateFormControl(data:TypeValeurResultatWithElements):FormGroup{
    return this.fb.group({
      libelle: data.libelle,
      code_categorie:data.code_categorie,
      id:data.id,
      typeElement:this.fb.array(data.relations.map((relation)=>this.generateTypeElementControl(relation)))
    })
  }

  generateValeurReferenceFormControl(valeurReference:ValeurReference) {
    let control = new FormControl(valeurReference.nouvelleValeur);
     control.addValidators(Validators.required);
    this.valeursReferencesForm.addControl(valeurReference.idValeurReference.toString(), control);
  }

  generateTypeElementControl(relation:TypeElementRel):FormGroup{
    return this.fb.group({
      id: relation.id,
      idtpv:relation.tpv_id,
      idtpe:relation.tpe_id,
      code:relation.code,
      libelle:relation.libelle,
      checked:relation.id===0? false:true
    })
  }

  private getValeurReferences() {
    this.apiAdelaService.getValeurReferences(undefined).subscribe(
      data => {
        // je vais parcourir le résultat pour fabriquer la datasource de la table HTML
        let tpvID        : number = 0;
        let arrayLenght!  : number;
        let arrayLine!   : AffichageValeurReference;
        
        this.afficherValeursReferences = false;

        // instantiation du formulaire
        this.valeursReferencesForm = this.fb.group({});

        // Vidage de la datasource du tableau html
        this.valeursReferences = new MatTableDataSource<AffichageValeurReference>();

        for (let index = 0; index < data.length; index++) {
          if (tpvID != data[index].idTypeValeur) {

            // Nouvelle ligne dans la table
            arrayLine = {typeValeur: <TypeElement>{}, poudreBlanche : <ValeurReference>{}, poudreBleue : <ValeurReference>{}, farineBle : <ValeurReference>{}, talc : <ValeurReference>{}}

            // Assignation du type de valeur à ligne
            arrayLine.typeValeur = data[index].typeValeur!; 
            arrayLenght = this.valeursReferences.data.push(arrayLine);

            tpvID = data[index].idTypeValeur;
          }
        
          // Assignation des valeurs de références à la ligne et gestion des control du formulaire
          switch (data[index].produitReference?.codeProduitReference) {
            case this.apiAdelaService.CONST_PRODUIT_REFERENCE_FARINE_BLE:
              this.valeursReferences.data[arrayLenght-1].farineBle = data[index];
              this.generateValeurReferenceFormControl(data[index]);
              break;

            case this.apiAdelaService.CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE:
              this.valeursReferences.data[arrayLenght-1].poudreBlanche = data[index];
              this.generateValeurReferenceFormControl(data[index]);
              break;

            case this.apiAdelaService.CONST_PRODUIT_REFERENCE_POUDRE_BLEUE:
              this.valeursReferences.data[arrayLenght-1].poudreBleue = data[index];
              this.generateValeurReferenceFormControl(data[index]);
              break;

            case this.apiAdelaService.CONST_PRODUIT_REFERENCE_TALC:
              this.valeursReferences.data[arrayLenght-1].talc = data[index];
              this.generateValeurReferenceFormControl(data[index]);
              break;

            default:
              break;
          }
        }
        
        this.afficherValeursReferences = true;
      }
    );
  }
}
