import { DatePipe, NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { saveAs } from "file-saver";
import { MatTableExporterModule } from 'mat-table-exporter';
import { TableAdela } from 'src/app/interfaces/adela/table-adela';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { DialogHistoriqueDossierComponent } from '../../dossiers/dialog-historique-dossier/dialog-historique-dossier.component';
import { ExportTableComponent } from '../../templates/export-table/export-table.component';
import { ApiFactureService } from 'src/app/services/api-facture.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-table-adela',
    templateUrl: './table-adela.component.html',
    styleUrls: ['./table-adela.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTableModule, MatTableResponsiveLargeDirective, MatTableExporterModule, NgIf, MatButtonModule
              , NgStyle, MatIconModule, ExportTableComponent, MatPaginatorModule, DatePipe, MatProgressSpinnerModule]
})
export class TableAdelaComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;
  @Input() dataSource     : TableAdela[] = [];
  @Output() refreshADELA  = new EventEmitter<boolean>();
  @Output() getDossier     = new EventEmitter<any>();

  apiFacture = inject(ApiFactureService)
  dataSourceMatTable: MatTableDataSource<TableAdela> = new MatTableDataSource<TableAdela>();

  // Gestion de l'affichage
  waitingResearch: boolean = false;
  ExpandPannel: boolean = false;

  profilUtilisateur$: ProfilUtilisateur = <ProfilUtilisateur>{};
  
  // Tableau des dossiers ADC
  displayedColumns: string[] = [];

  constructor(private _fb: FormBuilder
    , private authUser: ApiAuthentificationService
    , public apiADELA: ApiAdelaService
    , private toast: PersoSnackbarService
    , public dialog: MatDialog) {

  }

  ngOnInit(): void {
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
      
    }
    
    this.profilUtilisateur$ = this.authUser.userConnect;

    if ( this.profilUtilisateur$.droits_utilisateur.droit_module_admin_adela ) {
      this.displayedColumns = ['typeAnalyse','reference', 'etiquette', 'cabinet', 'expert', 'date', 'etat','idfacture', 'actions'];
    } else {
      this.displayedColumns = ['typeAnalyse','immatriculation', 'reference', 'etiquette', 'cabinet', 'date', 'etat','idfacture', 'actions'];
    }
  }

  ngAfterViewInit() {
    this.dataSourceMatTable.paginator = this.paginator;
    this.dataSourceMatTable.sort = this.sort;
  }

  ngOnChanges() {
    // obligatoire
    if(this.dataSource != undefined) {
      this.dataSourceMatTable.data = this.dataSource;
    }    
  }

  downloadInvoice(dossier:TableAdela){
    dossier.attenteDLFacture = true;

    this.apiFacture.getDownloadFacture(dossier.idfacture,'').subscribe(
      (data:any)=>{
        saveAs(data, 'facture_dossier_' + dossier.referenceDossierExpert + '.pdf');
        this.toast.showInfo("Votre facture est bien téléchargée")
        dossier.attenteDLFacture = false;
      }
    )
  }

  downloadDossier(id: any) {
    this.apiADELA.getDossierTelecharger(id)
      .subscribe(
        data => {

          if(data != undefined) {
            saveAs(data, `Confirmation-ADELA.pdf`);
          this.toast.showInfo('Confirmation de la demande d\'analyse téléchargé')
          }          
        },
        err => {    
          this.toast.showError('Problème rencontré lors du téléchargement de la demande d\'analyse.');     
          console.error('/!\ err: ' + err.message); 
        },
      ); 
  }

  public openFile(dossier:TableAdela) {
    this.getDossier.next(dossier)
  }

  dialogHistoriqueDossier(id: any) {
    const dialogRef = this.dialog.open(DialogHistoriqueDossierComponent, { panelClass: 'custom-dialog-container' });
    dialogRef.componentInstance.id = id
  }

  public onApercuRapport(dossier:TableAdela) { 
    dossier.attenteDLRapport = true;

    this.apiADELA.getTelechargerResultatAnalyse(dossier.id)
    .subscribe(
      (data) => {
        saveAs(data, `CLOTURE_`+dossier.habilitation+`_`+dossier.id.toString()+`.pdf`);
        this.toast.showInfo("Téléchargement effectué");
        dossier.attenteDLRapport = false;
      },
      (err) => {
        console.error('/!\\ error getTelechargerResultatAnalyse: '+err);
        dossier.attenteDLRapport = false;
      }
    );
  }
}
