<div>

    <ng-container *ngIf="isServiceFFEA; then isService else isExtranet"></ng-container>

    <!-- SERVICE FFEA -->
    <ng-template #isService>
        <form [formGroup]="profilUtilisateurForm" *ngIf="profilUtilisateur != undefined">
            <mat-card appearance="outlined">
                <mat-card-title>
                    <mat-icon>feed</mat-icon>
                    <span>Informations personnelles</span>
                </mat-card-title>
                <mat-card-content class="mat-card-content">
                    <div>

                        <mat-form-field>
                            <mat-label for="nom">Nom XX</mat-label>
                            <input  matInput 
                                    type="text" 
                                    formControlName="nom" 
                                    placeholder="Nom"
                                    appNom
                                oninput="this.value = this.value.toUpperCase()">
                            <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label for="prenom">Prénom </mat-label>
                            <input appTitleCase matInput type="text"
                                formControlName="prenom" placeholder="Prénom" appNom>
                            <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{
                                prenom?.errors?.erreur }}</mat-error>
                        </mat-form-field>


                        <mat-form-field>
                            <mat-label for="email">Email </mat-label>
                            <input matInput type="email" id="email" name="email" formControlName="email"
                                placeholder="Email">
                            <mat-hint>ATTENTION : Si vous modifiez l'email, votre login lors de la prochaine connexion à
                                l’extranet sera votre nouveau mail.</mat-hint>
                            <mat-error *ngIf="email?.hasError('checkValidatorMail')">{{ email?.errors?.checkValidatorMail }}</mat-error>
                        </mat-form-field>

                        <!-- Nouvelle gestion des groupes: -->

                        <mat-form-field appearance="outline" >
                            <mat-label>Groupe</mat-label>
                            <mat-select formControlName="idGroupe" [disabled]="!userConnect.droits_utilisateur.est_admin && !userConnect.droits_utilisateur.droit_admin_ffea">
                                <mat-option *ngFor="let groupe of groupes" [value]="groupe.id">
                                    {{groupe.nom}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-slide-toggle #accessAnea 
                                        formControlName="access_anea_partage" 
                                        style="margin-top: 40px;margin-left: 20px;">
                            <span>{{accessAnea.checked? 'Accès autorisé à Anea Partage' : 'Accès bloqué à Anea Partage' }}</span>
                        </mat-slide-toggle>
                       

                        <div *ngIf="ent_id != undefined && ent_id > 0">
                            <mat-form-field>
                                <mat-label>Rôle </mat-label>
                                <mat-select name="estDirigeant" formControlName="estDirigeant">
                                    <mat-option [value]="false">Réparateur·rice</mat-option>
                                    <mat-option [value]="true">Dirigeant·e</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-form-field *ngIf="!isServiceFFEA">
                            <mat-label for="telephone">Téléphone </mat-label>
                            <input matInput type="tel" id="telephone" name="telephone" formControlName="telephone"
                                placeholder="Téléphone" appTelephone>
                            <mat-error *ngIf="telephone?.invalid && (telephone?.dirty || telephone?.touched)">{{
                                telephone?.errors?.erreur }}</mat-error>
                        </mat-form-field>


                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <div class="button">
                        <div *ngIf="ent_id != undefined && ent_id > 0 && rel_utl_ent.est_active"
                            [matTooltipDisabled]="!isConcentrateurActif()"
                            matTooltip="Désactiver l'accès au concentrateur avant de désactiver l'utilisateur"
                            matTooltipPosition="left">
                            <button type="button" mat-button color="warn" (click)="onClickDisableEnableRelUtlEnt(false)"
                                [disabled]="isConcentrateurActif()">
                                <mat-icon>person_remove</mat-icon>
                                Désactiver
                            </button>
                        </div>

                        <div *ngIf="ent_id != undefined && ent_id > 0 && !rel_utl_ent.est_active">
                            <button type="button" mat-button color="primary"
                                (click)="onClickDisableEnableRelUtlEnt(true)">
                                <mat-icon>person_add</mat-icon>
                                Activer
                            </button>
                        </div>
                        <div class="div-container-between" style="padding: 8px;">
                            <button      *ngIf="!waiting"
                                        (click)="enregistrerUtilisateur()" 
                                        type                    = "submit" 
                                        mat-raised-button color = "primary"
                                        [disabled]              = "profilUtilisateurForm.pristine || (!profilUtilisateurForm.valid && profilUtilisateurForm.dirty)"
                                >
                                Enregistrer les modifications
                            </button>
                            <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </form>
    </ng-template>

    <!-- EXTRANET FFEA -->
    <ng-template #isExtranet>

        <form [formGroup]="profilUtilisateurForm" *ngIf="profilUtilisateur != undefined">
            <div *ngIf="ent_id <= 0">
                <mat-card appearance="outlined">
                    <mat-card-header>
                        <mat-card-title>
                            <div class="div-container-left">
                                <mat-icon>account_circle</mat-icon>
                                <span>Photo de profil</span>
                            </div>
                        </mat-card-title>

                    </mat-card-header>
                    <mat-card-content style="justify-content: center !important;" class=".mat-card-content">
                        <div class="img-profil">
                            <mat-icon class="grey-icon" style="transform: scale(8);"
                                *ngIf="!profilUtilisateurForm.get('profileImage')?.value">account_circle</mat-icon>
                            <img class="img-profil" *ngIf="profilUtilisateurForm.get('profileImage')?.value"
                                [src]="_sanitizer.bypassSecurityTrustResourceUrl(profilUtilisateurForm.get('profileImage')?.value!)">
                        </div>
                    </mat-card-content>
                    <mat-card-actions style="display: flex; justify-content: center !important;">
                        <button mat-button color="primary" (click)="onClickUpload()">
                            <mat-icon>cloud_upload</mat-icon>
                            Déposer
                        </button>
                        <button mat-button color="warn" (click)="onClickDelete()">
                            <mat-icon>delete</mat-icon>
                            Supprimer
                        </button>
                    </mat-card-actions>
                </mat-card>

                <mat-divider></mat-divider>

            </div>
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>
                        <div class="div-container-left">
                            <mat-icon>feed</mat-icon>
                            <span>Informations personnelles</span>
                        </div>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="mat-card-content">
                    <div>
                        <!-- Je considère que si l'utilisateur n'a pas accès au module acquittement ou à rdea certif alors il n'a pas accès au concentrateur et ne doit pas avoir le champ agrément visible -->
                        <mat-form-field *ngIf="profilUtilisateur.droits_utilisateur? profilUtilisateur.droits_utilisateur.droit_module_acquittement || profilUtilisateur.droits_utilisateur.droit_rdea :false">
                            <mat-label for="agrement_ve">Agrément VE</mat-label>
                            <input matInput [readonly]="!userConnect.droits_utilisateur.est_admin" type="text" id="agrement_ve" name="agrement_ve"
                                formControlName="agrement_ve" placeholder="XXXXXX-VE" maxlength="9" oninput="this.value = this.value.toUpperCase()">
                            <mat-error *ngIf="agrement_ve?.invalid && (agrement_ve?.dirty || agrement_ve?.touched)">{{
                                agrement_ve?.errors?.formatAgrementVE }}</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label for="nom">Nom</mat-label>
                            <input matInput 
                                    formControlName = "nom" 
                                    placeholder     = "Nom"
                                    oninput         = "this.value = this.value.toUpperCase()"
                                    maxlength       = "50"
                                    appNom
                                    >
                            <mat-error *ngIf="nom?.invalid && (nom?.dirty || nom?.touched)">{{ nom?.errors?.erreur }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label for="prenom">Prénom </mat-label>
                            <input  matInput
                                    appTitleCase
                                    type            = "text" 
                                    id              = "prenom" 
                                    name            = "prenom"
                                    formControlName = "prenom" 
                                    placeholder     = "Prénom"
                                    minlength       = "3"
                                    maxlength       = "50"
                                    >
                            <mat-error *ngIf="prenom?.invalid && (prenom?.dirty || prenom?.touched)">{{ prenom?.errors?.erreur }}</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label for="email">Email </mat-label>
                            <input matInput type="email" id="email" name="email" formControlName="email"
                                placeholder="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
                            <mat-hint>ATTENTION : Si vous modifiez l'email, votre login lors de la prochaine connexion à
                                l’extranet sera votre nouveau mail.</mat-hint>
                            <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">Le format de l'adresse
                                email est incorrect</mat-error>
                        </mat-form-field>

                        <!-- Nouvelle gestion des groupes: -->
                        <div *ngIf="userConnect.droits_utilisateur.est_admin || userConnect.droits_utilisateur.droit_admin_ffea">
                            <mat-form-field appearance="outline" >
                                <mat-label>Groupe</mat-label>
                                <mat-select formControlName="idGroupe">
                                    <mat-option *ngFor="let groupe of groupes" [value]="groupe.id">
                                        {{groupe.nom}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <mat-form-field>
                            <mat-label for="telephone">Téléphone </mat-label>
                            <input matInput 
                                    type            = "tel" 
                                    id              = "telephone" 
                                    name            = "telephone" 
                                    formControlName = "telephone"
                                    placeholder     = "Téléphone"
                                    appTelephone
                                    >
                            <mat-error *ngIf="telephone?.invalid && (telephone?.dirty || telephone?.touched)">{{
                                telephone?.errors?.erreur }}</mat-error>
                        </mat-form-field>
                        
                        <div *ngIf="ent_id != undefined && ent_id > 0">
                            <mat-slide-toggle 
                                formControlName = "estDirigeant" 
                                [checked]       = "isChecked"
                                (change)        = "onDirigeantChange($event)"
                                >
                                {{isChecked? 'Est dirigeant' : 'N\'est pas dirigeant' }}
                            </mat-slide-toggle>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions>
                    <div class="button">
                        <div *ngIf="ent_id != undefined && ent_id > 0 && rel_utl_ent.est_active"
                            [matTooltipDisabled]="!isConcentrateurActif()"
                            matTooltip="Désactiver l'accès au concentrateur avant de désactiver l'utilisateur"
                            matTooltipPosition="left">
                            <button type="button" mat-button color="warn" (click)="onClickDisableEnableRelUtlEnt(false)"
                                [disabled]="isConcentrateurActif()">
                                <mat-icon>person_remove</mat-icon>
                                Désactiver
                            </button>
                        </div>

                        <div *ngIf="ent_id != undefined && ent_id > 0 && !rel_utl_ent.est_active">
                            <button type="button" mat-button color="primary"
                                (click)="onClickDisableEnableRelUtlEnt(true)">
                                <mat-icon>person_add</mat-icon>
                                Activer
                            </button>
                        </div>
                        <div class="div-container-around" style="padding: 8px;">
                            <button  *ngIf                  = "!waiting"
                                    (click)                 = "enregistrerUtilisateur()" 
                                    type                    = "submit" 
                                    mat-raised-button color = "primary"
                                    [disabled]              = "profilUtilisateurForm.pristine || !(profilUtilisateurForm.valid && profilUtilisateurForm.dirty)">
                                Enregistrer les modifications
                            </button>
                            <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </form>

        <div *ngIf="ent_id != undefined && ent_id > 0 && (this.profilUtilisateur.agrement_ve? this.profilUtilisateur.agrement_ve.trim() != '':false) ">
            <mat-divider></mat-divider>

            <form [formGroup]="accesConcentrateurForm">
                <mat-card appearance="outlined">
                    <mat-card-title>Accès Concentrateur - {{ this.entite.raison_sociale }}</mat-card-title>
                    <mat-card-content class="mat-card-content">
                        <mat-slide-toggle name="autorisation" formControlName="autorisation" color="primary"
                            (change)="onToggle($event)">
                            {{(accesConcentrateurForm.controls.autorisation.value ? 'Accès autorisé à émettre au
                            Concentrateur des VE / VGE' : 'Accès refusé à émettre au Concentrateur des VE / VGE' )}}
                        </mat-slide-toggle>
                    </mat-card-content>
                </mat-card>
            </form>
        </div>

        <mat-divider></mat-divider>

        <!--  <app-update-password style="display: flex;"></app-update-password> -->
        <app-update-password ></app-update-password>

    </ng-template>

    <div
        *ngIf="userConnect.droits_utilisateur.est_admin || userConnect.droits_utilisateur.droit_admin_ffea">
        <mat-divider></mat-divider>

        <mat-card appearance="outlined">
            <mat-card-title>Activation / Désactivation du compte</mat-card-title>
            <mat-card-actions>
                <div class="button">
                    <button mat-raised-button type="submit" (click)="ActiveUnactiveUser()"
                        [ngStyle]="{ 'background-color' : (profilUtilisateur.actif ? '#f44336' : '#04970E') }"
                        style="color: #F9F9F9;">
                        <mat-icon>{{ profilUtilisateur.actif ? 'close' : 'check'}}</mat-icon>
                        {{ profilUtilisateur.actif ? 'Désactiver' : 'Activer'}} le compte
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>

</div>