import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TelephoneDirective } from 'src/app/directive/telephone.directive';
import { InvitationCollaborateurBdd } from 'src/app/interfaces/invitation-collaborateur/invitation-collaborateur-bdd';
import { ProfilUtilisateur } from 'src/app/interfaces/profil-user/profil-utilisateur';
import { ApiAuthentificationService } from 'src/app/services/api-authentification.service';
import { ApiEntiteService } from 'src/app/services/api-entite.service';
import { ApiInvitationCollaborateurService } from 'src/app/services/api-invitation-collaborateur.service';
import { ApiUtilisateurService } from 'src/app/services/api-utilisateur.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { CheckValidator } from 'src/app/validators/check.validator';
import { TelephoneDirective as TelephoneDirective_1 } from '../../../directive/telephone.directive';
import { TitleCaseDirective } from '../../../directive/title-case.directive';
import { GroupeService } from '../../groupe/data-access/groupe.service';
import { Groupe } from '../../groupe/groupe-interface';
import { MailValidator } from 'src/app/validators/mail.validator';

@Component({
    selector: 'app-inscription-collaborateur',
    templateUrl: './inscription-collaborateur.component.html',
    styleUrls: ['./inscription-collaborateur.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, ReactiveFormsModule, MatCardModule, MatFormFieldModule, MatInputModule, TitleCaseDirective, TelephoneDirective_1, MatSelectModule, MatOptionModule, MatIconModule, MatTooltipModule, MatButtonModule, MatProgressSpinnerModule]
})
export class InscriptionCollaborateurComponent implements OnInit {

  @Input() invitation: InvitationCollaborateurBdd = <InvitationCollaborateurBdd>{};
  
  collaborateur               : ProfilUtilisateur = <ProfilUtilisateur>{};  // Objet profilUtilisateur de l'interface
  groupes                     : Array<Groupe> = [];
  hidePasswordNewPassword     : boolean = true;
  hidePasswordConfirmPassword : boolean = true;
  isWaiting                   : boolean = false;

  // Interface responsive
  innerWidth   : any;
  innerHeight  : any;
  
  get email()         { return this.collaborateurForm.get('email'); }
  get nom()           { return this.collaborateurForm.get('nom'); }
  get prenom()        { return this.collaborateurForm.get('prenom'); }
  get agrement_ve()   { return this.collaborateurForm.get('agrement_ve'); }
  get telephone()     { return this.collaborateurForm.get('telephone'); }
  get new()           { return this.collaborateurForm.get('new'); }
  get confirmation()  { return this.collaborateurForm.get('confirmation'); }
  get id_groupe()     { return this.collaborateurForm.get('id_groupe'); }
  

  // Déclaration du formulaire
  collaborateurForm = this.fBuilder.group({
    id                          : new FormControl(0),
    agrement_ve                 : new FormControl(''),
    nom                         : new FormControl('', [Validators.required]),
    prenom                      : new FormControl('', [Validators.required]),
    email                       : new FormControl('', [Validators.required, this.MailValidator.checkValidatorMail()]),
    reinitialisation_motdepasse : new FormControl(false),
    telephone                   : new FormControl(''),
    date_creation               : new FormControl(''),
    date_derniere_modification  : new FormControl(''),
    actif                       : new FormControl(true),
    profileImage                : new FormControl<string|null>(null),
    id_groupe                   : new FormControl<number|null>(null, [Validators.required]),
    new                         : new FormControl('',[Validators.required, this.checkValidator.verificationFormatMotDePasse()]) ,
    confirmation                : new FormControl('',[Validators.required, this.checkValidator.verificationFormatMotDePasse()]) 
  });

  constructor(private apiUtilisateur    : ApiUtilisateurService
            , private apiInvitation     : ApiInvitationCollaborateurService
            , public apiAuthentification: ApiAuthentificationService
            , private fBuilder          : FormBuilder
            , private route             : Router
            , private toast             : PersoSnackbarService
            , private phoneDirective    : TelephoneDirective
            , private checkValidator    : CheckValidator
            , private apiGroupe         : GroupeService
            , private apiEntite         : ApiEntiteService
            , private MailValidator     : MailValidator){}

  ngOnInit(): void {
    
    this.getGroupes();

    this.collaborateurForm.controls['email'].disable();
    this.collaborateurForm.controls['nom'].disable();
    this.collaborateurForm.controls['prenom'].disable();
    this.collaborateurForm.controls['id_groupe'].disable();
  }
  
  ngOnChanges(): void {
    

    if (this.invitation.expert===true) {
      this.agrement_ve?.setValidators((Validators.required))
    }

    this.collaborateurForm.patchValue({
      id:                           0,
      agrement_ve:                  '',
      nom:                          this.invitation.nom,
      prenom:                       this.invitation.prenom,
      email:                        this.invitation.email,      
      reinitialisation_motdepasse:  false,
      telephone:                    '',
      date_creation:                null,
      date_derniere_modification:   null,
      actif:                        true,
      profileImage:                 '',
      id_groupe:                    this.invitation.id_groupe
    });
  }

  public isMobileFormat() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    
    if (this.innerWidth <= 500 || this.innerHeight <= 500) {
      return true;
    } else {
      return false;
    }
  }

  public inscriptionCollaborateur() {
    this.isWaiting = true
    
    if (this.collaborateurForm.controls.new.value != this.collaborateurForm.controls.confirmation.value) {
      this.toast.showError("Le mot de passe et sa confirmation ne coïncident pas");
      return;
    }
      
    if (this.invitation.expert === true) {
      const agrement:number = this.collaborateurForm.controls.agrement_ve.value? +this.collaborateurForm.controls.agrement_ve.value : 0
      this.collaborateur.agrement_ve                  = this.apiUtilisateur.formateAgrement(agrement, this.invitation.expert);
    }else{
      this.collaborateur.agrement_ve = ''
    }
    this.collaborateur.nom                          = this.collaborateurForm.controls.nom.value? this.collaborateurForm.controls.nom.value :'';
    this.collaborateur.prenom                       = this.collaborateurForm.controls.prenom.value? this.collaborateurForm.controls.prenom.value : '';
    this.collaborateur.email                        = this.collaborateurForm.getRawValue().email!;
    this.collaborateur.telephone                    = this.phoneDirective.getPhoneNumberWithoutFormat(this.collaborateurForm.controls.telephone.value!);
    this.collaborateur.motdepasse                   = this.collaborateurForm.controls.new.value!;
    this.collaborateur.idgrp                        = this.collaborateurForm.controls.id_groupe.value ? this.collaborateurForm.controls.id_groupe.value : 0;
    this.collaborateur.reinitialisation_motdepasse  = false;
    this.collaborateur.actif                        = true;
   
    this.apiUtilisateur.postUtilisateur(this.collaborateur, this.invitation.entite.id)
      .subscribe(
        (data: ProfilUtilisateur) => {
          this.collaborateur = data;
          this.invitation.id_utl_destinataire = this.collaborateur.id;
          this.invitation.destinataire = this.collaborateur;

          this.apiInvitation.postAccepterInvitation(this.invitation)
            .subscribe(
              (data) => {
                this.toast.showInfo("Inscription réussie.");
                this.route.navigate(['/login']);
                this.isWaiting = false
              },
              (err) => {
                this.toast.showAlert("Une erreur est survenue." + err.message);
                this.isWaiting = false
              },
              () => {}
            );          
        },
        (err) => {
          this.toast.showError("Une erreur est survenue lors de l'inscription." + err.message);
          this.isWaiting = false
        }
      );
  }

  public getGroupes() {
    this.apiGroupe.getGroupesParCodeEntite(this.apiEntite.CONST_CODE_TYPE_ENTITE_EXPERT)
      .subscribe(
        (data: Groupe[]) => {
          this.groupes = data;
        },
        (err) => {
          this.toast.showError("Une erreur est survenue dans la recherche des rôles." + err.message);
        }
      );    
  }

    /* VERFICATION FORMAT SAISIE */
  // Vérification du format du nom
  // Le nom doit faire entre 1 et 50 caractères avec uniquement des majuscules, tirets ou des espaces
  verificationFormatNom(fcControler: FormControl): ValidationErrors | null {
    let s_nom:    string  = fcControler.value;
    let a_regex:  any     = /^[A-Z\-\ ]{1,50}$/;
    
    if (!a_regex.test(s_nom)) {      
      return { erreur: 'Le nom doit faire entre 1 et 50 caractères avec uniquement des majuscules, tirets ou des espaces' }
    }

    return null;
  }

  // Vérification du format du prénom
  // Le prénom doit faire entre 1 et 50 caractères avec une majuscule en première lettre et le reste en minuscule
  verificationFormatPrenom(fcControler: FormControl): any {
    let s_prenom: string  = fcControler.value;
    let a_regex:  any     = /^[A-Z][A-Za-z\-\ ]{2,50}$/;
    
    if (!a_regex.test(s_prenom)) {      
      return { erreur: 'Le prénom doit faire entre 1 et 50 caractères avec une majuscule en première lettre, tirets ou des espaces' }
    }

    return null;
  }

  // Vérification du format
  verificationFormatRoleEntite(control: FormControl): any {    
    if ( control.value != 0 && control.value != null ) {
      return null;
    } else {
      return { erreur: 'Sélectionnez un rôle pour l\'entite en cours' };
    }    
  }
}
