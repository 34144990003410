<mat-card appearance="outlined">
    <mat-card-title>Résultats de l'analyse</mat-card-title>
    
    <div [formGroup]="AnalyseForm"  *ngIf="done">

        <div class="content" *ngIf="resultsSansCatArray.length > 0">
            <div class="titlebox">Résultats sans catégorie</div>
            <div    formArrayName   = "resultsSansCatArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsSansCatArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label class="field-label-ellipsis">{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit" *ngIf="result.get('resultValue')!.value != ''">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
        </div>

        <div class="content" *ngIf="resultsPCArray.length > 0 && !form_bsm">
            <div class="titlebox">Résultats physico-chimiques</div>
            <div    formArrayName   = "resultsPCArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsPCArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label class="field-label-ellipsis">{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit" *ngIf="result.get('resultValue')!.value != ''">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
            
        </div>

        <div class="content" *ngIf="resultsPCBArray.length > 0 && form_bsm">
            <div class="titlebox">Résultats physico-chimiques bsm</div>
            <div    formArrayName   = "resultsPCBArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsPCBArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeur textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label class="field-label-ellipsis">{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span matSuffix class="unit" *ngIf="result.get('resultValue')!.value != ''">{{result.get('resultUnit')!.value}}</span>
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
        </div>      

        <div class="content" *ngIf="resultsSpectArray.length > 0 && formAdela.tpe_code == apiAdela.CONST_TYPE_ELEMENT_FLUIDE">
            <div class="titlebox">Résultats spectrométriques en ppm</div>
            <div    formArrayName   = "resultsSpectArray" 
                    class           = "listeValeur"
                    >
                <mat-form-field *ngFor      = "let result of resultsSpectArray.controls; let i=index" 
                                [formGroupName] = "i"
                                class           = "formFieldValeurSpectometrie textarea"
                                appearance      = "outline"
                                >
                
                    <mat-label class="field-label-ellipsis">{{result.get('resultLabel')!.value}}</mat-label>
                    <input  matInput
                            type            = "text" 
                            [value]         = "result.get('resultValue')!.value" 
                            oninput         = "this.value = this.value.toUpperCase()" 
                            formControlName = "resultValue"
                                    >       
                    <span class="oldValue" *ngIf="!result.get('resultInit')!.value">{{result.get('resultValueInit')!.value}}*</span>
                </mat-form-field>
                
            </div>
        </div>

         <div class="content" *ngIf="resultsSpectArray.length > 0 && formAdela.tpe_code == apiAdela.CONST_TYPE_ELEMENT_POUDRE">
            <div class="titlebox">Résultats spectrométriques en ppm</div>
            <div class="table-container">
                <table mat-table matTableResponsive [dataSource]="valeursReferences" class="table-primary" *ngIf="afficherValeursReferences" formArrayName = "resultsSpectArray">
                                
                    <!-- Type valeur Column -->
                    <ng-container matColumnDef="TYPEVALEUR">
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne">Composants</th>
                        <td mat-cell *matCellDef="let valeurReference" class="td-premiere-colonne">{{valeurReference.typeValeur.libelle}}</td>
                    </ng-container>
            
                    <!-- Poudre blanche Column -->
                    <ng-container [matColumnDef]="apiAdela.CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE">
                        <th mat-header-cell *matHeaderCellDef> Poudre blanche </th>
                        <td mat-cell *matCellDef="let valeurReference"> {{valeurReference.poudreBlanche.nouvelleValeur}}</td>
                    </ng-container>
            
                    <!-- Poudre blanche Column -->
                    <ng-container [matColumnDef]="apiAdela.CONST_PRODUIT_REFERENCE_POUDRE_BLEUE">
                        <th mat-header-cell *matHeaderCellDef> Poudre bleue </th>
                        <td mat-cell *matCellDef="let valeurReference"> {{valeurReference.poudreBleue.nouvelleValeur}}</td>
                    </ng-container>
            
                    <!-- Farine blé Column -->
                    <ng-container [matColumnDef]="apiAdela.CONST_PRODUIT_REFERENCE_FARINE_BLE">
                        <th mat-header-cell *matHeaderCellDef> Farine de blé </th>
                        <td mat-cell *matCellDef="let valeurReference"> {{valeurReference.farineBle.nouvelleValeur}}</td>
                    </ng-container>
            
                    <!-- Talc Column -->
                    <ng-container [matColumnDef]="apiAdela.CONST_PRODUIT_REFERENCE_TALC">
                        <th mat-header-cell *matHeaderCellDef> Talc </th>
                        <td mat-cell *matCellDef="let valeurReference">{{valeurReference.talc.nouvelleValeur}}</td>
                    </ng-container>
            
                    <!-- Echantillon Column -->
                    <ng-container matColumnDef="ECHANTILLON">
                        <th mat-header-cell *matHeaderCellDef> Echantillon </th>
                        <td mat-cell *matCellDef="let valeurReference"  [formGroupName]="getSpectFormArrayIndex(valeurReference.typeValeur.id)">
                            <mat-form-field class = "formFieldValeurSpectometrie textarea" appearance = "outline">
                                <input  matInput
                                        type            = "text"
                                        [value]         = "getSpectFormArrayControl(valeurReference.typeValeur.id)!.get('resultValue')!.value"    
                                        formControlName = "resultValue"
                                >
                                <span matSuffix class="unit">{{getSpectFormArrayControl(valeurReference.typeValeur.id)!.get('resultUnit')!.value}}</span>
                                <span class="oldValue" *ngIf="!getSpectFormArrayControl(valeurReference.typeValeur.id)!.get('resultInit')!.value">{{getSpectFormArrayControl(valeurReference.typeValeur.id)!.get('resultValueInit')!.value}}*</span>
                            </mat-form-field>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsValeursReference"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsValeursReference;"></tr>
                </table>
            </div>
        </div>
        
        <div class="oldValue" style="margin:10px;" >* Valeur d'origine</div>
        
        <div class="content" *ngIf="form_bsm">
            <div class="titlebox">Notation générale</div>
                <form [formGroup]="form_notation_bsm">
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus de la combustion</mat-label>
                        <mat-select name="polluant_combustion" id="polluant_combustion" formControlName="polluant_combustion">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du système d'injection</mat-label>
                        <mat-select name="polluant_injection" id="polluant_injection" formControlName="polluant_injection">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus de la filtration de l'air</mat-label>
                        <mat-select name="polluant_filtration" id="polluant_filtration" formControlName="polluant_filtration">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du circuit de refroidissement</mat-label>
                        <mat-select name="polluant_refroiddisement" id="polluant_refroiddisement" formControlName="polluant_refroidissement">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du haut-moteur</mat-label>
                        <mat-select name="polluant_haut_moteur" id="polluant_haut_moteur" formControlName="polluant_haut_moteur">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    <mat-form-field>
                        <mat-label>Quantité de polluants issus du bas-moteur</mat-label>
                        <mat-select name="polluant_bas_moteur" id="polluant_bas_moteur" formControlName="polluant_bas_moteur">                              
                            <mat-option *ngFor="let note of notation$" [value]="note">
                                    {{ note }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label><b>Note globale</b></mat-label>
                        <mat-select name="note_globale" id="note_globale" formControlName="note_globale">                              
                            <mat-option *ngFor="let note of notation_globale$" [value]="note.id">
                                <img [src]="note.chemin" class="img-note-globale"/>  {{ note.libelle }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
        </div>

        <div class="content">
            <div class="titlebox">Références et photos</div>
            <div class="div-container-left" style="align-items:stretch">
                <div>
                    <p style="margin: 25px;"></p>
                    <mat-card-subtitle>Référence laboratoire</mat-card-subtitle>
                    <br>
                    <!-- Référence du dossier Vernolab -->
                    <mat-form-field>
                        <mat-label>Référence laboratoire</mat-label>
                        <input matInput type="text" [value]="analyseDossier.num_labo" oninput="this.value = this.value.toUpperCase()" readonly>        
                    </mat-form-field>
                </div>
        
                <div *ngIf="imageToShow.length > 0">
                    <div>
                        <p style="margin: 25px;"></p>
                        <mat-card-subtitle>Photos</mat-card-subtitle>
                        <br>
                    </div>
                    <div class="div-container-left">
                        <!-- Photos envoyées par Vernolab dans les résultats d'analyse -->                    
                        <div *ngFor="let photo of imageToShow" class="photo-wrap">
                            <img [src]="_sanitizer.bypassSecurityTrustResourceUrl(photo)" class="photo-center"/>                        
                            <button mat-icon-button class="photo-button-zoom-in" (click)="onClickZoomIn(photo)"><mat-icon color="primary" class="icon-display-32">zoom_in</mat-icon></button>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>       

        <app-phrasier (newItemEvent)="addPhrasier($event)" *ngIf="showPhrasier" [showAllTable]="false" [defaultTypeElementCode]="formAdela.tpe_code"></app-phrasier>

        <div class="content" *ngIf="!form_bsm">
            <div class="titlebox">Interprétation des résultats</div>
            <div *ngIf="formAdela.etatCode === apiAdela.CONST_ETAT_ADELA_5_EnCoursDiagnostic">
                <button mat-flat-button  (click)="onClickPhrasier()"><mat-icon color="primary" class="icon-display-32">description</mat-icon></button>
                <span class="span-button">Phrasier</span>
            </div>
            <mat-form-field class="textarea" style="max-width:100%">
                <mat-label>Interprétation</mat-label>
                <textarea
                    matInput
                    class           = "textarea"
                    formControlName = "interpretation"
                    (keyup)         = "autoGrowTextZone($event)"
                    type            = "text"
                ></textarea>
            </mat-form-field>
        </div>

        <div class="div-container-right" style="margin-top: 15px;" >
            <button   
                mat-flat-button style="text-align: center;" 
                color       = "primary" 
                (click)     = "close(false)"
                type        = "button"
                >
                <mat-icon>west</mat-icon>
                Fermer
            </button>

            <div *ngIf="!waiting && (formAdela.etatCode? formAdela.etatCode != apiAdela.CONST_ETAT_ADELA_6_Cloture  : true )" class="div-container-right">
                <button   
                    mat-flat-button style="text-align: center;" 
                    color       = "primary" 
                    (click)     = "save()"
                    type        = "button"
                    [disabled]  = "AnalyseForm.invalid && !dossierFormValid"
                    >
                    <mat-icon>save</mat-icon>
                    Enregistrer
                </button>
                <button   
                    mat-flat-button style="text-align: center;" 
                    color       = "warn" 
                    (click)     = "close(true)"
                    type        = "button"
                    [disabled]  = "AnalyseForm.invalid && !dossierFormValid"
                    >
                    <mat-icon>lock</mat-icon>
                    Cloturer
                </button>
            </div>
            <div *ngIf="!waiting && formAdela.etatCode === apiAdela.CONST_ETAT_ADELA_6_Cloture" class="div-container-right">

                <button mat-flat-button 
                        style       = "text-align: center;" 
                        color       = "primary" 
                        [disabled]  = "waiting"
                        (click)     = "modifierDiagnostic()"
                        type        = "button">
                    <mat-icon>edit</mat-icon>
                    <span class="span-button">Modifier le diagnostic</span>
                </button>
                
                <button mat-flat-button 
                        style       = "text-align: center;" 
                        color       = "primary" 
                        [disabled]  = "waiting"
                        (click)     = "onApercuRapport()"
                        type        = "button">
                    <mat-icon>download</mat-icon>
                    <span class="span-button">Télécharger le rapport</span>
                </button>
                                    
                <button mat-flat-button 
                        style       = "text-align: center;" 
                        color       = "primary" 
                        [disabled]  = "waiting" 
                        (click)     = "onEnvoyerRapport()"
                        type        = "button">
                    <mat-icon>email</mat-icon>
                    <span class="span-button">Recevoir le rapport</span>
                </button>
            </div>
            <mat-spinner *ngIf="waiting" diameter="24"></mat-spinner>
        </div>
    </div>
</mat-card>
