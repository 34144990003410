import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';
import { pairwise, startWith } from 'rxjs/operators';
import { ResultatAnalyse, ValeurResultat, AffichageValeurReference, TypeElement, ValeurReference } from 'src/app/interfaces/adela/resultat-analyse';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { NoteGlobale } from '../resultat-analyse-adela/resultat-analyse-adela.component';
import { NotesBsm } from 'src/app/interfaces/adela/notes-bsm';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DialogPhotoResultatAnalyseAdelaComponent } from '../dialog-photo-resultat-analyse-adela/dialog-photo-resultat-analyse-adela.component';
import { PhrasierComponent } from '../../phrasier/espace-phrasier/phrasier.component';
import { EnregistrerResultatAnalyse } from 'src/app/interfaces/adela/enregistrer-resultat-analyse';
import { saveAs } from 'file-saver';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { FormulaireAdela } from 'src/app/interfaces/adela/formulaire-adela';

@Component({
    selector: 'app-resultat-analyse-edit',
    templateUrl: './resultat-analyse-edit.component.html',
    styleUrls: ['./resultat-analyse-edit.component.scss'],
    standalone: true,
    imports: [MatCardModule, NgIf, ReactiveFormsModule, NgFor, MatFormFieldModule, MatInputModule,
              MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatOptionModule, PhrasierComponent,
              MatSelectModule,MatExpansionModule, MatTableModule]
})
export class ResultatAnalyseEditComponent implements OnChanges {

@Input()          formAdela       : FormulaireAdela = <FormulaireAdela>{};
@Input()          form_bsm          : boolean = false
@Input()          destinataire      : string = ''; // @ Email du destinataire
@Input()          dossierFormValid  : boolean = false
@Output()         closeDossier      = new EventEmitter<boolean>();
@Output()         saveDossier       = new EventEmitter<void>();
@Output()         nouvelEtat        = new EventEmitter<string>();

analyseDossier    : ResultatAnalyse = <ResultatAnalyse>{};
resultsPC         : ValeurResultat[] = [];
resultsPCB        : ValeurResultat[] = []
resultsSpect      : ValeurResultat[] = []
resultsSansCat    : ValeurResultat[] = []
done              : boolean = false;
categoriesForm    : Array<FormArray> =[]
AnalyseForm!      : FormGroup;
form_notation_bsm : any;
waiting           : boolean = false
idResultat        : number = 0
notation$         : Array<number> = [1,2,3,4,5];
notation_globale$ : Array<NoteGlobale> = [{id: 1, libelle: 'Rouge', chemin: 'assets/img/BSM_Rouge.png'}, {id: 2, libelle: 'Orange', chemin: 'assets/img/BSM_Orange.png'}, {id: 3, libelle: 'Vert', chemin: 'assets/img/BSM_Vert.png'}];
notes_bsm         : NotesBsm = <NotesBsm>{};
cheminImage       : string = '';
imageToShow       : Array<any> = [];
showPhrasier      : boolean = false;
valeursReferences  = new MatTableDataSource<AffichageValeurReference>();
displayedColumnsValeursReference   : string[] = ['TYPEVALEUR',
                                                  this.apiAdela.CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE,
                                                  this.apiAdela.CONST_PRODUIT_REFERENCE_POUDRE_BLEUE,
                                                  this.apiAdela.CONST_PRODUIT_REFERENCE_FARINE_BLE,
                                                  this.apiAdela.CONST_PRODUIT_REFERENCE_TALC,
                                                'ECHANTILLON'];
afficherValeursReferences : boolean = false;

get resultsPCArray(){
  return this.AnalyseForm.get(['resultsPCArray']) as FormArray
}

get resultsPCBArray(){
  return this.AnalyseForm.get(['resultsPCBArray']) as FormArray
}

get resultsSpectArray(){
  return this.AnalyseForm.get(['resultsSpectArray']) as FormArray
}

get resultsSansCatArray(){
  return this.AnalyseForm.get(['resultsSansCatArray']) as FormArray
}

  constructor(
              public apiAdela       : ApiAdelaService,
              private fb            : FormBuilder,
              private toast         : PersoSnackbarService,
              public _sanitizer     : DomSanitizer,
              public dialog         : MatDialog
  ) {
    // Création des formulaires
     this.AnalyseForm = this.fb.group({        
      interpretation  : new FormControl(this.analyseDossier.interpretation, Validators.required),
      resultsPCArray  :this.fb.array([]),
      resultsPCBArray : this.fb.array([]),
      resultsSpectArray : this.fb.array([]),
      resultsSansCatArray : this.fb.array([])
    })

    this.form_notation_bsm = this.fb.group({
      polluant_combustion     : [0],
      polluant_injection      : [0],
      polluant_filtration     : [0],
      polluant_refroidissement: [0],
      polluant_haut_moteur    : [0],
      polluant_bas_moteur     : [0],
      note_globale            : [0]
    });
  }

  ngOnChanges(changes: SimpleChanges) : void {

    if ( changes.formAdela != undefined && changes.formAdela.currentValue != undefined && changes.formAdela.isFirstChange() == true ) {
      
      if (this.formAdela.tpe_code == this.apiAdela.CONST_TYPE_ELEMENT_POUDRE) {
        this.getValeurReferences();  
      }

      this.apiAdela.getResultatAnalyse(this.formAdela.idDossier, false).subscribe(
        (data : ResultatAnalyse) => {
          this.analyseDossier = data;
          this.idResultat     = this.analyseDossier.id
      
/*           this.resultsPC      = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'PHYSICOCHIMIQUE' && result.id_resultat_analyse!=0)
          this.resultsPCB     = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'PHYSICOCHIMIQUE_BSM' && result.id_resultat_analyse!=0)
          this.resultsSpect   = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'SPECTROMETRIE' && result.id_resultat_analyse!=0)
          this.resultsSansCat = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === '' && result.id_resultat_analyse!=0) */

          this.resultsPC      = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'PHYSICOCHIMIQUE')
          this.resultsPCB     = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'PHYSICOCHIMIQUE_BSM')
          this.resultsSpect   = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === 'SPECTROMETRIE')
          this.resultsSansCat = this.analyseDossier.tabst_ValeurResultat.filter((result) => result.st_TypeValeurResultat.code_categorie === '')

           this.AnalyseForm.patchValue({
            interpretation      : this.analyseDossier.interpretation
          })

          this.AnalyseForm.setControl('resultsPCArray', this.fb.array(this.resultsPC.map((res)=>this.generateFormControl(res))));
          this.AnalyseForm.setControl('resultsPCBArray', this.fb.array(this.resultsPCB.map((res)=>this.generateFormControl(res))));
          this.AnalyseForm.setControl('resultsSpectArray', this.fb.array(this.resultsSpect.map((res)=>this.generateFormControl(res))));
          this.AnalyseForm.setControl('resultsSansCatArray', this.fb.array(this.resultsSansCat.map((res)=>this.generateFormControl(res))));
          
          this.getPhotosResultatAnalyse(this.idResultat, this.analyseDossier.etiquette);
          
          if (this.formAdela.etatCode == this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
            this.AnalyseForm.disable()
          }

          this.done = true;

          this.AnalyseForm.updateValueAndValidity();
        }
      )
    }

    if ( changes.form_bsm && changes.form_bsm.currentValue != changes.form_bsm.previousValue) {
      if (this.formAdela.tpe_code == this.apiAdela.CONST_TYPE_ELEMENT_FLUIDE && changes.form_bsm.currentValue == true) {
        this.getNotesBSM(this.idResultat);
      }

      this.changeEtatChamps(changes.form_bsm.currentValue);
    }

  }

  public getNotesBSM(idResultat: number) {
    this.apiAdela.getNotesBSM(idResultat)
    .subscribe(
      (data: NotesBsm) => {
      
        let note_globale : NoteGlobale | undefined;
        
        this.notes_bsm = data;

        note_globale = this.notation_globale$.find(notation => notation.id == this.notes_bsm.note_globale)        
        
        if(note_globale != undefined) {
          this.cheminImage = note_globale.chemin;
          this.form_notation_bsm.patchValue({          
            note_globale: note_globale.id
          });  
        }

        this.form_notation_bsm.patchValue({
          polluant_combustion     : this.notes_bsm.quantite_combustion,
          polluant_injection      : this.notes_bsm.quantite_injection,
          polluant_filtration     : this.notes_bsm.quantite_filtre_air,
          polluant_refroidissement: this.notes_bsm.quantite_circuit_refroidissement,
          polluant_haut_moteur    : this.notes_bsm.quantite_haut_moteur,
          polluant_bas_moteur     : this.notes_bsm.quantite_bas_moteur
        });
      },
      (err) => { console.error('/!\\ error getNotesBSM: '+err);}
    );
  }

  public changeEtatChamps(avecBSM: boolean) {
    if(this.formAdela.etatCode === this.apiAdela.CONST_ETAT_ADELA_5_EnCoursDiagnostic || this.formAdela.etatCode === this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
      if(this.formAdela.etatCode === this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
        this.AnalyseForm.disable();
        this.form_notation_bsm.controls['polluant_combustion'].disable();
        this.form_notation_bsm.controls['polluant_injection'].disable();
        this.form_notation_bsm.controls['polluant_filtration'].disable();
        this.form_notation_bsm.controls['polluant_refroidissement'].disable();
        this.form_notation_bsm.controls['polluant_haut_moteur'].disable();
        this.form_notation_bsm.controls['polluant_bas_moteur'].disable();
        this.form_notation_bsm.controls['note_globale'].disable();
      } else {
        if (avecBSM) {
          this.AnalyseForm.disable();
          this.form_notation_bsm.controls['polluant_combustion'].enable();
          this.form_notation_bsm.controls['polluant_injection'].enable();
          this.form_notation_bsm.controls['polluant_filtration'].enable();
          this.form_notation_bsm.controls['polluant_refroidissement'].enable();
          this.form_notation_bsm.controls['polluant_haut_moteur'].enable();
          this.form_notation_bsm.controls['polluant_bas_moteur'].enable();
          this.form_notation_bsm.controls['note_globale'].enable();
        } else {
          this.AnalyseForm.enable();
          this.form_notation_bsm.controls['polluant_combustion'].disable();
          this.form_notation_bsm.controls['polluant_injection'].disable();
          this.form_notation_bsm.controls['polluant_filtration'].disable();
          this.form_notation_bsm.controls['polluant_refroidissement'].disable();
          this.form_notation_bsm.controls['polluant_haut_moteur'].disable();
          this.form_notation_bsm.controls['polluant_bas_moteur'].disable();
          this.form_notation_bsm.controls['note_globale'].disable();
        }
      }

      this.AnalyseForm.updateValueAndValidity();
      this.form_notation_bsm.updateValueAndValidity();
    }
  }

  public getPhotosResultatAnalyse(id: number, numEtiquette: string) {
    this.apiAdela.postPhotosResultatAnalyse(id, numEtiquette)
    .subscribe(
      (data : ResultatAnalyse) => {
        
        if(data != null && data.tabs_Photos != undefined) {          
          for(let i = 0; i < data.tabs_Photos.length; i++) {
            this.imageToShow.push(data.tabs_Photos[i]);
          }          
        }
      },
      (err) => { console.error('/!\\ error getPhotosResultatAnalyse: '+err);}
    );
  }
  
  onClickZoomIn(photoBase64 : any) {
    const dialogRef = this.dialog.open(DialogPhotoResultatAnalyseAdelaComponent, {data : {photo: photoBase64}});
  }

  onClickPhrasier() {
    this.showPhrasier = !this.showPhrasier;
  }

  addPhrasier(newItem: string) {
    this.AnalyseForm.controls['interpretation'].setValue(this.AnalyseForm.controls.interpretation.value + newItem);
    this.showPhrasier = false;
  }

  generateFormControl(data:ValeurResultat):FormGroup{
    const group =  this.fb.group({
      resultValue         : data.valeur_new? data.valeur_new : data.valeur,
      resultValueSaved    : data.valeur_new? data.valeur_new : data.valeur,
      resultValueInit     : data.valeur,
      resultInit          : data.valeur_new? false : true, 
      resultUnit          : data.st_TypeValeurResultat.unite,
      resultLabel         : data.st_TypeValeurResultat.libelle,
      resultTypeValueID   : data.st_TypeValeurResultat.id,
      resultId            : data.id
    })

    group.controls['resultValue'].valueChanges.pipe(startWith(group.controls['resultValue'].value), pairwise()).subscribe(
      ([prev, next])=> {
        if (group.controls['resultValueInit'].value === prev) {
          group.controls['resultInit'].setValue(false)
          group.controls['resultValueInit'].setValue(prev)
        }
        if (next ===group.controls['resultValueInit'].value) {
          group.controls['resultInit'].setValue(true)
          group.controls['resultValueInit'].setValue('')
        }
      }
    )
    return group
  }
  
  save(forClosed : boolean = false){
    let enregistrerResultatAnalyse : EnregistrerResultatAnalyse = <EnregistrerResultatAnalyse>{};

    this.waiting = true;    
    
    enregistrerResultatAnalyse.id_Resultat        = this.idResultat;
    enregistrerResultatAnalyse.appel_pour_bsm     = this.form_bsm;
    enregistrerResultatAnalyse.appel_pour_cloture = forClosed;
    enregistrerResultatAnalyse.id_dossier         = this.formAdela.idDossier;
    enregistrerResultatAnalyse.interpretation     = this.AnalyseForm.value.interpretation;
    enregistrerResultatAnalyse.analyse            = [];
    enregistrerResultatAnalyse.analyse            = this.buildAnalyse(enregistrerResultatAnalyse.analyse, this.AnalyseForm.value.resultsPCArray)
    enregistrerResultatAnalyse.analyse            = this.buildAnalyse(enregistrerResultatAnalyse.analyse, this.AnalyseForm.value.resultsPCBArray)
    enregistrerResultatAnalyse.analyse            = this.buildAnalyse(enregistrerResultatAnalyse.analyse, this.AnalyseForm.value.resultsSpectArray)
    enregistrerResultatAnalyse.analyse            = this.buildAnalyse(enregistrerResultatAnalyse.analyse, this.AnalyseForm.value.resultsSansCatArray)

    if(this.form_bsm) {
      enregistrerResultatAnalyse.note_bsm = <NotesBsm>{};
      enregistrerResultatAnalyse.note_bsm.id = this.notes_bsm.id;

      if(this.form_notation_bsm.value.note_globale != undefined) {
        enregistrerResultatAnalyse.note_bsm.note_globale = this.form_notation_bsm.controls.note_globale.value;
      }
      if(this.form_notation_bsm.value.polluant_bas_moteur != undefined) {
        enregistrerResultatAnalyse.note_bsm.quantite_bas_moteur = this.form_notation_bsm.controls.polluant_bas_moteur.value;
      }
      if(this.form_notation_bsm.value.polluant_refroidissement != undefined){
        enregistrerResultatAnalyse.note_bsm.quantite_circuit_refroidissement = this.form_notation_bsm.controls.polluant_refroidissement.value;
      }
      if(this.form_notation_bsm.value.polluant_combustion != undefined) {
        enregistrerResultatAnalyse.note_bsm.quantite_combustion = this.form_notation_bsm.controls.polluant_combustion.value;
      }
      if(this.form_notation_bsm.value.polluant_filtration != undefined) {
        enregistrerResultatAnalyse.note_bsm.quantite_filtre_air = this.form_notation_bsm.controls.polluant_filtration.value;
      }
      if(this.form_notation_bsm.value.polluant_haut_moteur != undefined) {
        enregistrerResultatAnalyse.note_bsm.quantite_haut_moteur = this.form_notation_bsm.controls.polluant_haut_moteur.value;
      }
      if(this.form_notation_bsm.value.polluant_injection != undefined) {
        enregistrerResultatAnalyse.note_bsm.quantite_injection = this.form_notation_bsm.controls.polluant_injection.value;
      }      
    }

    this.apiAdela.enregistrerResultatAnalyse(enregistrerResultatAnalyse)
    .subscribe(
      (data) => {
        if (forClosed) {
          this.toast.showInfo('Votre dossier a bien été clôturé')
          this.closeDossier.next(true);
        } else {
          this.saveDossier.next();
          // this.toast.showInfo('Vos modifications ont bien été prises en compte')
        }
        
        this.waiting = false;
      },
      (err) => { 
        console.error('/!\\ error enregistrerResultatAnalyse: '+err);
        this.waiting = false;
      }
    );
  }

  close(enregistrer : boolean){
    if (enregistrer) {
      this.save(true);
    } else {
      this.closeDossier.next(false);
    } 
  }

  buildAnalyse(analyseValues:Array<ValeurResultat>,resultArray:Array<any>){
    
    resultArray.forEach((analyse:any) => {
      const analyseFound = this.analyseDossier.tabst_ValeurResultat.find((res) => res.id === analyse.resultId)

      if (analyseFound?.valeur_new) {
        if (analyseFound.valeur_new != analyse.resultValue) {
          analyseValues.push(analyse)
        }
      }else{
        //Etat initial : on n'a jamais modifié cette valeur
        if (analyseFound?.valeur != analyse.resultValue) {
          analyseValues.push(analyse)
        }
      }
    });
    
    return analyseValues
  }

  public onApercuRapport() { 
    this.waiting = true;

    this.apiAdela.getTelechargerResultatAnalyse(this.formAdela.idDossier)
    .subscribe(
      (data) => {
        saveAs(data, `CLOTURE_`+this.formAdela.habilitation+`_`+this.formAdela.idDossier.toString()+`.pdf`);
        this.toast.showInfo("Téléchargement effectué");
        this.waiting = false;
      },
      (err) => {
        console.error('/!\\ error getTelechargerResultatAnalyse: '+err);
        this.waiting = false;
      }
    );
  }

  public onEnvoyerRapport() { 
    this.waiting = true;

    this.apiAdela.getRenvoyerResultatAnalyseParMail(this.formAdela.idDossier)
    .subscribe(
      (data) => {

        this.toast.showInfo("Mail envoyé à "+this.destinataire);
        this.waiting = false;
      },
      (err) => {
        console.error('/!\\ error getRenvoyerResultatAnalyseParMail: '+err);
        this.waiting = false;
      }
    );
  }

  public modifierDiagnostic() {
    this.waiting = true;

    this.apiAdela.putEtatDossier(this.formAdela.idDossier, this.apiAdela.CONST_ETAT_ADELA_5_EnCoursDiagnostic).subscribe(
      (data) => {
        this.formAdela.etatCode = this.apiAdela.CONST_ETAT_ADELA_5_EnCoursDiagnostic;
        this.changeEtatChamps(this.form_bsm);
        
        // il faut également recharger les nouvelles valeurs de références.
        this.getValeurReferences(); 
        
        this.nouvelEtat.emit(this.formAdela.etatCode);
        this.waiting  = false;
      },
      (err) => {
        console.error('/!\\ error modifierDiagnostic: '+err);
        this.waiting = false;
      }
    )
  }

  public autoGrowTextZone(event : any) {
    if (event.target.style.height != null && event.target.style.height != undefined) {
      event.target.style.height = "0px";
      event.target.style.height = (event.target.scrollHeight + 25)+"px";
    }
  }

  private getValeurReferences() {

    let dateValeurReference : Date | undefined = undefined;

    if (this.formAdela.etatCode == this.apiAdela.CONST_ETAT_ADELA_6_Cloture) {
      dateValeurReference = this.formAdela.dateCloture;
    }

    this.apiAdela.getValeurReferences(dateValeurReference).subscribe(
      data => {
        // je vais parcourir le résultat pour fabriquer la datasource de la table HTML
        let tpvID        : number = 0;
        let arrayLenght! : number;
        let arrayLine!   : AffichageValeurReference;
        
        this.afficherValeursReferences = false;


        // Vidage de la datasource du tableau html
        this.valeursReferences = new MatTableDataSource<AffichageValeurReference>();

        for (let index = 0; index < data.length; index++) {
          if (tpvID != data[index].idTypeValeur) {

            // Nouvelle ligne dans la table
            arrayLine = {typeValeur: <TypeElement>{}, poudreBlanche : <ValeurReference>{}, poudreBleue : <ValeurReference>{}, farineBle : <ValeurReference>{}, talc : <ValeurReference>{}}

            // Assignation du type de valeur à ligne
            arrayLine.typeValeur = data[index].typeValeur!; 
            arrayLenght = this.valeursReferences.data.push(arrayLine);

            tpvID = data[index].idTypeValeur;
          }
        
          // Assignation des valeurs de références à la ligne et gestion des control du formulaire
          switch (data[index].produitReference?.codeProduitReference) {
            case this.apiAdela.CONST_PRODUIT_REFERENCE_FARINE_BLE:
              this.valeursReferences.data[arrayLenght-1].farineBle = data[index];
              break;

            case this.apiAdela.CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE:
              this.valeursReferences.data[arrayLenght-1].poudreBlanche = data[index];
              break;

            case this.apiAdela.CONST_PRODUIT_REFERENCE_POUDRE_BLEUE:
              this.valeursReferences.data[arrayLenght-1].poudreBleue = data[index];
              break;

            case this.apiAdela.CONST_PRODUIT_REFERENCE_TALC:
              this.valeursReferences.data[arrayLenght-1].talc = data[index];
              break;

            default:
              break;
          }
        }
        
        this.afficherValeursReferences = true;
      }
    )
  }

    public getSpectFormArrayControl(idTypeValeur : number) : AbstractControl | undefined {
      let control : AbstractControl<any> | undefined;
      
      // console.log('idTypeValeur' + idTypeValeur);
      control = this.resultsSpectArray.controls.find(control => control.value.resultTypeValueID == idTypeValeur);
      // console.log(control);

      return control;
    }

    public getSpectFormArrayIndex(idTypeValeur : number) : number {
      let index: number;
      index = this.resultsSpectArray.controls.findIndex(control => control.value.resultTypeValueID == idTypeValeur)
      // console.log(index);

      return index;
    }
}
