<mat-tab-group style="margin-top: -20px;">
    <mat-tab>
        <ng-template mat-tab-label>
            <div class="div-container-left">
                <span class="material-symbols-outlined">
                    settings
                </span>
                <span class="mat-tab-label">Visibilité des composants</span>
            </div>
        </ng-template>

        <ng-template matTabContent>
            <mat-card appearance="outlined" *ngIf="typeValeurElementForm">
                
                <mat-card-content class=".mat-card-content">
                    
                    <div [formGroup]="typeValeurElementForm" class="flex">
                        <ng-container formArrayName="elementArray">
                            
                            <div class="content" >
                                <div class="titlebox">Composants physico-chimiques</div>
                                <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                                    <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                                </mat-grid-list>
                                <div    *ngFor          = "let result of elementArray().controls; let i=index"
                                        [formGroupName] = "i"
                                        class           = "formFieldValeur"
                                        appearance      = "outline">
                                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value==='PHYSICOCHIMIQUE'">
                                        <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                                        
                                        
                                            <ng-container  formArrayName="typeElement">
                                                    <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                                        <mat-grid-tile colspan="1">
                                                            <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                            </mat-slide-toggle>
                                                        </mat-grid-tile>
                                                        
                                                    </div>
                                            </ng-container>
                                        
                                    </mat-grid-list>
                                </div>
                            </div>
            
                            <div class="content" >
                                <div class="titlebox">Composants physico-chimiques bsm</div>
                                <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                                    <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                                </mat-grid-list>
                                <div    *ngFor          = "let result of elementArray().controls; let i=index"
                                        [formGroupName] = "i"
                                        class           = "formFieldValeur"
                                        appearance      = "outline">
                                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value==='PHYSICOCHIMIQUE_BSM'">
                                        <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                                        
                                        
                                            <ng-container  formArrayName="typeElement">
                                                    <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                                        <mat-grid-tile colspan="1">
                                                            <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                            </mat-slide-toggle>
                                                        </mat-grid-tile>
                                                        
                                                    </div>
                                            </ng-container>
                                        
                                    </mat-grid-list>
                                </div>
                            </div>
            
                            <div class="content" >
                                <div class="titlebox">Composants spectrométriques</div>
                                <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                                    <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                                </mat-grid-list>
                                <div    *ngFor          = "let result of elementArray().controls; let i=index"
                                        [formGroupName] = "i"
                                        class           = "formFieldValeur"
                                        appearance      = "outline">
                                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value==='SPECTROMETRIE'">
                                        <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                                        
                                        
                                            <ng-container  formArrayName="typeElement">
                                                    <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                                        <mat-grid-tile colspan="1">
                                                            <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                            </mat-slide-toggle>
                                                        </mat-grid-tile>
                                                        
                                                    </div>
                                            </ng-container>
                                        
                                    </mat-grid-list>
                                </div>
                            </div>

                            <div class="content" >
                                <div class="titlebox">Composants sans catégorie</div>
                                <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" class="title">
                                    <mat-grid-tile colspan="3">Composante</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Huile</mat-grid-tile>
                                    <mat-grid-tile colspan="1">Poudre</mat-grid-tile>
                                </mat-grid-list>
                                <div    *ngFor          = "let result of elementArray().controls; let i=index"
                                        [formGroupName] = "i"
                                        class           = "formFieldValeur"
                                        appearance      = "outline">
                                    <mat-grid-list cols="5" rowHeight="30px" style="width: 500px;" *ngIf="result.get('code_categorie')!.value===''">
                                        <mat-grid-tile colspan="3">{{result.get('libelle')!.value}}</mat-grid-tile>
                                        
                                        
                                            <ng-container  formArrayName="typeElement">
                                                    <div *ngFor = "let element of typeElement(i).controls;let j=index">
                                                        <mat-grid-tile colspan="1">
                                                            <mat-slide-toggle [formControl]="checked(i,j)" [checked]="element.get('checked')!.value">
                                                            </mat-slide-toggle>
                                                        </mat-grid-tile>
                                                        
                                                    </div>
                                            </ng-container>
                                        
                                    </mat-grid-list>
                                </div>
                            </div>
                        </ng-container>
            
                    </div>
                </mat-card-content>
                <mat-card-actions align="end">
                   <button mat-raised-button color="primary" (click)="enregistrer()">Enregistrer</button>
               </mat-card-actions>
            </mat-card>
        </ng-template>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <div class="div-container-left">
                <span class="material-symbols-outlined">
                    123
                </span>
                <span class="mat-tab-label">Valeurs de référence des composants</span>
            </div>
        </ng-template>
        
        <ng-template matTabContent>
            <mat-card appearance="outlined">
                <mat-card-content class=".mat-card-content">
                    <div class="content" [formGroup]="valeursReferencesForm">
                        <div class="titlebox">Valeur de références des composants</div>
                    
                        <table mat-table matTableResponsive [dataSource]="valeursReferences" class="table-primary" *ngIf="afficherValeursReferences">
                    
                            <!-- Type valeur Column -->
                            <ng-container matColumnDef="TYPEVALEUR">
                                <th mat-header-cell *matHeaderCellDef class="premiere-colonne">Composants</th>
                                <td mat-cell *matCellDef="let valeurReference" class="td-premiere-colonne">{{valeurReference.typeValeur.libelle}}</td>
                            </ng-container>

                            <!-- Poudre blanche Column -->
                            <ng-container [matColumnDef]="apiAdelaService.CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE">
                                <th mat-header-cell *matHeaderCellDef> Poudre blanche </th>
                                <td mat-cell *matCellDef="let valeurReference">
                                    <mat-form-field class = "formFieldValeur textarea" appearance = "outline">
                                        <input  matInput
                                                type                = "text" 
                                                [(ngModel)]         = "valeurReference.poudreBlanche.nouvelleValeur"
                                                [formControlName]   = "valeurReference.poudreBlanche.idValeurReference"
                                        >
                                        <span matSuffix class="oldValue" *ngIf="valeurReference.poudreBlanche.nouvelleValeur != valeurReference.poudreBlanche.ancienneValeur">{{valeurReference.poudreBlanche.ancienneValeur.toString()+"*"}}</span>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- Poudre blanche Column -->
                            <ng-container [matColumnDef]="apiAdelaService.CONST_PRODUIT_REFERENCE_POUDRE_BLEUE">
                                <th mat-header-cell *matHeaderCellDef> Poudre bleue </th>
                                <td mat-cell *matCellDef="let valeurReference"> 
                                    <mat-form-field class = "formFieldValeur textarea" appearance = "outline">
                                        <input  matInput
                                                type                = "text" 
                                                [(ngModel)]         = "valeurReference.poudreBleue.nouvelleValeur"  
                                                [formControlName]   = "valeurReference.poudreBleue.idValeurReference"
                                        >
                                        <span matSuffix class="oldValue" *ngIf="valeurReference.poudreBleue.nouvelleValeur != valeurReference.poudreBleue.ancienneValeur">{{valeurReference.poudreBleue.ancienneValeur.toString()+"*"}}</span>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- Farine blé Column -->
                            <ng-container [matColumnDef]="apiAdelaService.CONST_PRODUIT_REFERENCE_FARINE_BLE">
                                <th mat-header-cell *matHeaderCellDef> Farine de blé </th>
                                <td mat-cell *matCellDef="let valeurReference">
                                    <mat-form-field class = "formFieldValeur textarea" appearance = "outline">
                                        <input  matInput
                                                type                = "text" 
                                                [(ngModel)]         = "valeurReference.farineBle.nouvelleValeur"  
                                                [formControlName]   = "valeurReference.farineBle.idValeurReference"
                                        >
                                        <span matSuffix class="oldValue" *ngIf="valeurReference.farineBle.nouvelleValeur != valeurReference.farineBle.ancienneValeur">{{valeurReference.farineBle.ancienneValeur.toString()+"*"}}</span>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <!-- Talc Column -->
                            <ng-container [matColumnDef]="apiAdelaService.CONST_PRODUIT_REFERENCE_TALC">
                                <th mat-header-cell *matHeaderCellDef> Talc </th>
                                <td mat-cell *matCellDef="let valeurReference">
                                    <mat-form-field class = "formFieldValeur textarea" appearance = "outline">
                                        <input  matInput
                                                type                = "text" 
                                                [(ngModel)]         = "valeurReference.talc.nouvelleValeur"  
                                                [formControlName]   = "valeurReference.talc.idValeurReference"
                                        >
                                        <span matSuffix class="oldValue" *ngIf="valeurReference.talc.nouvelleValeur != valeurReference.talc.ancienneValeur">{{valeurReference.talc.ancienneValeur.toString()+"*"}}</span>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsValeursReference"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsValeursReference;"></tr>
                        </table>
                    </div>   
                </mat-card-content>
                <mat-card-actions align="end">
                    <div class="oldValue" style="margin:10px;" >* Valeur d'origine</div>
                    <button mat-raised-button color="primary"*ngIf="!waitingSave" (click)="enregistrerValeursReferences()">Enregistrer</button>
                    <mat-spinner diameter="24" *ngIf="waitingSave"></mat-spinner>
                </mat-card-actions>
            </mat-card>
        </ng-template>
    </mat-tab>
</mat-tab-group>
