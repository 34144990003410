        
            <mat-card-subtitle>Mes Dossiers</mat-card-subtitle>

            <div class="container">
                <table 
                    mat-table 
                    matTableResponsiveLarge 
                    matTableExporter 
                    [dataSource]="dataSourceMatTable" 
                    #exporter="matTableExporter" 
                    [hiddenColumns]="[7]" 
                    class="table-primary">
                    <!-- Type d'analyse Column -->
                    <ng-container matColumnDef="typeAnalyse" >
                        <th mat-header-cell *matHeaderCellDef class="premiere-colonne" > Type d'analyse </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.typeElement}} </td>
                    </ng-container>

                    <!-- Immatriculation Column -->
                    <ng-container matColumnDef="immatriculation" *ngIf="!profilUtilisateur$.droits_utilisateur.droit_module_admin_adela">
                        <th mat-header-cell *matHeaderCellDef > Immatriculation </th>
                        <td mat-cell *matCellDef="let dossier" class="colonne-immat"> {{dossier.immatriculation}} </td>
                    </ng-container>
                    
                    <!-- Etiquette Column -->
                    <ng-container matColumnDef="etiquette">
                        <th mat-header-cell *matHeaderCellDef> N° Etiquette </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.etiquette}} </td>
                    </ng-container>

                    <!-- Reference Column -->
                    <ng-container matColumnDef="reference">
                        <th mat-header-cell *matHeaderCellDef> Référence </th>
                        <td mat-cell *matCellDef="let dossier" class="small-screen">
                            <button mat-flat-button class="custom-button" target="_blank" (click)="downloadDossier(dossier.id)"
                                style="text-decoration: underline;">{{dossier.referenceDossierExpert}}</button>
                        </td>
                    </ng-container>

                    <!-- Cabinet Column -->
                    <ng-container matColumnDef="cabinet">
                        <th mat-header-cell *matHeaderCellDef> Cabinet </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.raisonSocialeEntite}} </td>
                    </ng-container>

                    <!-- Expert Column -->
                    <ng-container matColumnDef="expert" *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_adela">
                        <th mat-header-cell *matHeaderCellDef> Expert </th>
                        <td mat-cell *matCellDef="let dossier"> {{dossier.NomExpert}} {{dossier.prenomExpert}} </td>
                    </ng-container>

                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef style="width: 92px;"> Date </th>
                        <td mat-cell *matCellDef="let dossier"> {{ (dossier.dateEnvoi == '0000-00-00T00:00:00.000' || dossier.dateEnvoi == '' ? '1900-01-01' : dossier.dateEnvoi ) | date: 'dd/MM/yyyy'  }}
                        </td>
                    </ng-container>

                    <!-- Etat Column -->
                    <ng-container matColumnDef="etat">
                        <th mat-header-cell *matHeaderCellDef class="etat-padding"> État </th>
                        <td mat-cell *matCellDef="let dossier" class="etat-padding">
                            <button mat-stroked-button class="button-etat button-width" color="primary"
                                [ngStyle]="{ color: apiADELA.getColorByStatus(dossier.etatCode) }"
                                (click)="dialogHistoriqueDossier(dossier.id)">{{dossier.etatLibelle}}</button>
                        </td>
                    </ng-container>

                     <!-- Facture Column -->
                    <ng-container matColumnDef="idfacture">
                        <th mat-header-cell *matHeaderCellDef  >Facture</th>
                        <td mat-cell *matCellDef="let dossier">
                            <button 
                                mat-icon-button 
                                *ngIf       = "dossier.idfacture>0 && !dossier.attenteDLFacture"
                                color       = "primary"
                                matTooltip  = "Télécharger la facture"
                                (click)     = "downloadInvoice(dossier)"
                            >
                                <mat-icon>download</mat-icon>
                            </button>
                            <mat-spinner *ngIf="dossier.attenteDLFacture" style="margin: auto;" diameter="24"></mat-spinner>
                        </td>
                    </ng-container>


                    <!-- Rapport Column -->
                    <ng-container matColumnDef="actions" *ngIf="!profilUtilisateur$.droits_utilisateur.droit_module_admin_adela">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne" style="width: 60px;"> Rapport </th>
                        <td mat-cell *matCellDef="let dossier">
                            <button mat-icon-button [disabled]="dossier.etatCode != 'CLOTURE'" *ngIf="!dossier.attenteDLRapport" color="primary" style="width: 48px; height: 48px;" (click)="onApercuRapport(dossier)">
                                <mat-icon class="icon-display-32" color="accent" svgIcon="{{ dossier.etatCode === 'CLOTURE' ? 'download' :'file_download_off' }}">
                                    <span class="cdk-visually-hidden">{{ dossier.etatCode === 'CLOTURE' ? "download" :"file_download_off" }}</span>
                                </mat-icon>
                            </button>
                            <mat-spinner *ngIf="dossier.attenteDLRapport" style="margin: auto;" diameter="24"></mat-spinner>                            
                        </td>
                    </ng-container>

                    <!-- Dossier Column -->
                    <ng-container matColumnDef="actions" *ngIf="profilUtilisateur$.droits_utilisateur.droit_module_admin_adela">
                        <th mat-header-cell *matHeaderCellDef class="derniere-colonne"></th>
                        <td mat-cell *matCellDef="let dossier">
                            <button mat-icon-button class="button-etat" color="primary"                            
                            style="width: 48px; height: 48px;"
                            (click)="openFile(dossier)">
                                <mat-icon color="primary" svgIcon="{{ apiADELA.getIcone(dossier.etatCode) }}">
                                    <span class="cdk-visually-hidden">{{ apiADELA.getIconeName(dossier.etatCode) }}</span>
                                </mat-icon></button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="div-container-between" style="align-items: center;background-color:white">
                    <app-export-table [fileExportName]="'EXTRANET - Mes dossiers ADELA'" [exporter]="exporter" [nb_ligne]="this.dataSourceMatTable.data.length"></app-export-table>
                    <mat-paginator [pageSizeOptions]="[10,25,50]"
                        showFirstLastButtons 
                        aria-label="Nombre de lignes visibles...">
                     </mat-paginator>
                </div>
                
            </div>
    