import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { DialogHistoriqueDossierComponent } from '../components/dossiers/dialog-historique-dossier/dialog-historique-dossier.component';
import { Analyse } from '../interfaces/adela/analyse';
import { Compagnie } from '../interfaces/adela/compagnie';
import { DossierFacture } from '../interfaces/adela/dossier-adela';
import { EnregistrerResultatAnalyse } from '../interfaces/adela/enregistrer-resultat-analyse';
import { Etiquette, Sequence } from '../interfaces/adela/etiquette';
import { FormulaireAdela } from '../interfaces/adela/formulaire-adela';
import { ListeElements } from '../interfaces/adela/liste-elements';
import { ListeParametrage } from '../interfaces/adela/liste-parametrage';
import { ListeTypeAffaire } from '../interfaces/adela/liste-type-affaire';
import { NotesBsm } from '../interfaces/adela/notes-bsm';
import { ResultatAnalyse, TypeElement, TypeElementRel, TypeValeurResultat, ValeurReference } from '../interfaces/adela/resultat-analyse';
import { TableAdela } from '../interfaces/adela/table-adela';
import { FiltreCommande } from '../interfaces/commande/commande';
import { Etat } from '../interfaces/dossier/etat';
import { HistoriqueDossier } from '../interfaces/dossier/historique-dossier';
import { Fichier } from '../interfaces/fichier';
import { ApiAuthentificationService } from './api-authentification.service';
import { PersoSnackbarService } from './perso-snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ApiAdelaService {

  urlApi: string = '/api';

  CONST_PARAM_ID      : string = 'id' as const;

  CONST_ETAT_ADELA_1_Depose	              : string = 'DEPOSE'			
  CONST_ETAT_ADELA_2_Valide				        : string = 'VALIDE'
  CONST_ETAT_ADELA_3_EnAttenteEchantillon	: string = 'ATTENTE_ECHANTILLON'
  CONST_ETAT_ADELA_4_EnCoursAnalyse		    : string = 'ANALYSE'
  CONST_ETAT_ADELA_5_EnCoursDiagnostic	  : string = 'DIAGNOSTIC'
  CONST_ETAT_ADELA_6_Cloture				      : string = 'CLOTURE'
  CONST_ETAT_ADELA_X_Refuse		            : string = 'REFUSE'		

  CONST_LIB_ETAT_ADELA_1_Depose	              : string = 'Déposé'			
  CONST_LIB_ETAT_ADELA_2_Valide				        : string = 'Validé'
  CONST_LIB_ETAT_ADELA_3_EnAttenteEchantillon	: string = 'En attente de l\'échantillon'
  CONST_LIB_ETAT_ADELA_4_EnCoursAnalyse		    : string = 'En cours d\'analyse'
  CONST_LIB_ETAT_ADELA_5_EnCoursDiagnostic	  : string = 'En cours de diagnostic'
  CONST_LIB_ETAT_ADELA_6_Cloture				      : string = 'Clôturé'
  CONST_LIB_ETAT_ADELA_X_Refuse		            : string = 'Refusé'		

  CONST_TYPE_ELEMENT_FLUIDE                   : number = 1
  CONST_TYPE_ELEMENT_POUDRE                   : number = 2
  
 // Constantes pour les organes de prelevement
  CONST_ORGANE_PRELEV_AUCUN                    : string = 'AUCU'
  CONST_ORGANE_PRELEV_MOTEUR                   : string = 'MOTE'
  CONST_ORGANE_PRELEV_BV_MANUEL                : string = 'BVMA'
  CONST_ORGANE_PRELEV_BV_AUTO                  : string = 'BVAU'
  CONST_ORGANE_PRELEV_RESERVOIR_COMBUSTIBLE    : string = 'RECO'
  CONST_ORGANE_PRELEV_CIRCUIT_REFROIDISSEMENT  : string = 'CIRE'
  CONST_ORGANE_PRELEV_CIRCUIT_FREINAGE         : string = 'CIFR'
  CONST_ORGANE_PRELEV_PONT_AVANT                : string = 'POAV'
  CONST_ORGANE_PRELEV_PONT_ARRIERE             : string = 'POAR'
  CONST_ORGANE_PRELEV_BOITE_TRANSFERT          : string = 'BOTR'
  CONST_ORGANE_PRELEV_BOITIER_DIRECTION        : string = 'BODI'
  CONST_ORGANE_PRELEV_RESERVOIR_ADBLUE         : string = 'READ'
  CONST_ORGANE_PRELEV_CIRCUIT_HYDRAULIQUE      : string = 'CIHY'

  // Constantes pour les types de fluide
  CONST_TYPE_FLUIDE_AUCUN                   : string = 'AUCUN'
  CONST_TYPE_FLUIDE_COMBUSTIBLE             : string = 'COMBUSTIBLE'
  CONST_TYPE_FLUIDE_HUILE_SERVICE           : string = 'HUILE'
  CONST_TYPE_FLUIDE_LIQUIDE_FREIN           : string = 'LIQUIDE_FREIN'
  CONST_TYPE_FLUIDE_LIQUIDE_REFROIDISSEMENT : string = 'LIQUIDE_REFROIDISSEMENT'
  CONST_TYPE_FLUIDE_ADBLUE                  : string = 'ADBLUE'
  CONST_TYPE_FLUIDE_AUTRE                   : string = 'AUTRE'
  CONST_TYPE_FLUIDE_LIQUIDE_LAVE_GLACE      : string = 'LIQUIDE_LAVE_GLACE'

  // Constantes pour les produits de référence
  CONST_PRODUIT_REFERENCE_POUDRE_BLANCHE    : string = 'POUDRE_BLANCHE'
  CONST_PRODUIT_REFERENCE_POUDRE_BLEUE      : string = 'POUDRE_BLEUE'
  CONST_PRODUIT_REFERENCE_FARINE_BLE        : string = 'FARINE_BLE'
  CONST_PRODUIT_REFERENCE_TALC              : string = 'TALC'

  // permet de spécifier le format d'échange de données entre le front et le back
  jsonContentType = new HttpHeaders().set('Content-Type', 'application/json');

  typeValeurElements$     = this.http.get<TypeValeurResultat[]>(this.urlApi+ `/adela/typevaleurelement`);
  typeElements$           = this.http.get<TypeElement[]>(this.urlApi+ `/adela/typeelement`);
  
  constructor(
    private http: HttpClient
    , private toast: PersoSnackbarService
    , private authUser: ApiAuthentificationService
    , public dialog: MatDialog
    , private router: Router
    ) { }

  dossiersAFacturer$ = this.http.get<any>(this.urlApi+'/adela/dossier/dossiers/afacturer')

  public getDossiersAFacturer(filtre:FiltreCommande){
    return this.http.patch<any>(this.urlApi+'/adela/dossier/dossiers/afacturer',filtre)
  }

  //Facturer manuellement les dossiers du tableau fourni:
  public facturerDossiers(dossiers:any[]):Observable<DossierFacture[]>{
    return this.http.post<DossierFacture[]>( this.urlApi +'/adela/dossier/dossiers/facturer',dossiers)
  }

  public getLiensTypeValeurElement():Observable<TypeValeurResultat[]>{
    return this.http.get<TypeValeurResultat[]>(this.urlApi+ `/adela/typevaleurelement`);
  }

  public getTypeElement():Observable<TypeElement[]>{
    return this.http.get<TypeElement[]>(this.urlApi+ `/adela/typeelement`).pipe(shareReplay(1),catchError(this.handleError));
  }
  
  public enregistreLiensTypeValeurElement(typesElementRel:TypeElementRel[]){
    return this.http.post<TypeValeurResultat>(this.urlApi+ `/adela/enregistreliens`,typesElementRel);
  }

  public enregistrerAnalyse(form_adela: FormulaireAdela): Observable<FormulaireAdela>{
    return this.http.post<FormulaireAdela>(this.urlApi + `/adela`, form_adela);
  }

  public  getDossier(id: number): Observable<FormulaireAdela> {
    return this.http.get<FormulaireAdela>(this.urlApi + `/adela/dossier/` + id);
  }

  public getAllFolder(dataFilter: any): Observable<TableAdela> {
    return this.http.post<TableAdela>( this.urlApi + `/adela/dossier`, dataFilter);
  }

  // Liste de toutes les liste des combos pour le formulaire (excepter la liste des compagnies et cabinet)
  public getAllListe(): Observable<ListeParametrage> {
    return this.http.get<ListeParametrage>(this.urlApi + `/adela/listes`)
  }

  // Liste des compagnies d'assurance
  public getCompagnie(): Observable<Compagnie[]> {
    return this.http.get<Compagnie[]>(this.urlApi + `/dossier/compagnie`)
  }

  // Liste des type d'analyse. listeUtiles (cf. déclaration locale)
  public getTypeAnalyse(): Observable<ListeTypeAffaire[]> {
    return this.http.get<ListeTypeAffaire[]>(this.urlApi + `/adela/type-analyse`).pipe(shareReplay(1),catchError(this.handleError))
  }
  // Liste des type d'analyse. listeUtiles (cf. déclaration locale)
  public getCombustible(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/combustible`)
  }
  // Liste des fluide. listeUtiles (cf. déclaration locale)
  public getTypeFluide(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/fluide`).pipe(shareReplay(1),catchError(this.handleError))
  }
  // Liste des organe. listeUtiles (cf. déclaration locale)
  public getOrgane(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/organe-prelevement`).pipe(shareReplay(1),catchError(this.handleError))
  }
  // Liste des lieu. listeUtiles (cf. déclaration locale)
  public getLieu(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/lieu-prelevement`)
  }
  // Liste des type incendie. listeUtiles (cf. déclaration locale)
  public getIncendie(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/incendie`)
  }
  // Liste des energie. listeUtiles (cf. déclaration locale)
  public getEnergie(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/energie`)
  }
  // Liste des usage. listeUtiles (cf. déclaration locale)
  public getUsage(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/usage`)
  }
  // Liste des usage. listeUtiles (cf. déclaration locale)
  public getTemperature(): Observable<ListeElements[]> {
    return this.http.get<ListeElements[]>(this.urlApi + `/adela/temperature`)
  }
  // Liste des services et analyses du laboratoire
  public getAnalyse(all:number = 0) {
    return this.http.get<Analyse[]>(this.urlApi + `/adela/prestation/`+all).pipe(shareReplay(1),catchError(this.handleError))
  }
  public getAnalyseFromSelection(idTypeAnalyse: number, idOrgane: number, idFluide: number, code_type_element:number=1) {
    return this.http.get<Analyse[]>(this.urlApi + `/adela/prestation/` + idTypeAnalyse +'/'+ idOrgane + '/' + idFluide+ '/' + code_type_element)
  }

  //Suppression d'une prestation
  public deletePrestation(idPrestation : number):Observable<any>{
    return this.http.delete(this.urlApi+`/adela/prestation/`+idPrestation)
  }

  // Valide la demande d'analyse ADELA
  public validerDemandeADELA(idDossier: number, form_adela: FormulaireAdela): Observable<FormulaireAdela> {
    return this.http.put<FormulaireAdela>(this.urlApi + `/adela/valider/`+idDossier , form_adela);
  }

  // Refuse la demande d'analyse ADELA
  public refuserDemandeADELA(idDossier: number, form_adela: FormulaireAdela): Observable<FormulaireAdela> {
    return this.http.put<FormulaireAdela>(this.urlApi + `/adela/refuser/`+idDossier , form_adela);
  }

  // Modifie la demande d'analyse ADELA (prestation demandée et prestation retenue)
  public modifierDemandeADELA(idDossier: number, form_adela: FormulaireAdela): Observable<FormulaireAdela> {
    return this.http.put<FormulaireAdela>(this.urlApi + `/adela/modifier/`+idDossier , form_adela);
  }

  // Récupère les résultats d'analyse ADELA
  public getResultatAnalyse(idDossier: number,onlyValuesThere : boolean) {
    return this.http.get<ResultatAnalyse>(this.urlApi + `/adela/resultat/`+idDossier + `/` + onlyValuesThere);
  }


  // Récupère les notes BSM d'analyse ADELA
  public getNotesBSM(idResultat: number) {
    return this.http.get<NotesBsm>(this.urlApi + `/adela/notes-bsm/`+idResultat);
  }

  // Récupère les photos des résultats d'analyse ADELA
  public postPhotosResultatAnalyse(id: number, numEtiquette: string): Observable<ResultatAnalyse> {
    return this.http.post<ResultatAnalyse>(this.urlApi + `/adela/photos/`+id+`/`+numEtiquette, { responseType: 'json'});
  }
  
  // Enregistrer les résultats d'analyse
  public enregistrerResultatAnalyse(resultat : EnregistrerResultatAnalyse) { 
    return this.http.post(this.urlApi + `/adela/resultat`, resultat, { responseType: 'json'});
  }

  // Téléchargement des résultats d'analyse
  public getTelechargerResultatAnalyse(id: any): Observable<any> {
    return this.http.get(this.urlApi + '/adela/telechargement-resultat?id='+id, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} })
  }

  public getRenvoyerResultatAnalyseParMail(id: any): Observable<any> {
    return this.http.get(this.urlApi + '/adela/mail-resultat?id='+id, { responseType: "json" })
  }
  
  // Liste des états ADELA
  public getEtat(): Observable<Etat[]> {
    return this.http.get<Etat[]>(this.urlApi + `/adela/etat`);
  }

  public putEtatDossier(idDossier : number, nouvelEtatDossier : string): Observable<any> {
    return this.http.put(this.urlApi+'/adela/etat/'+idDossier+'/'+nouvelEtatDossier, {});  
  }

  // Chargements des étiquettes
  public getEtiquettes() : Observable<Etiquette[]> { 
    return this.http.get<Etiquette[]>(this.urlApi+"/adela/etiquette")
      .pipe(
        tap((etiquettes : Etiquette[]) => {
          return etiquettes;
        }), 
        catchError((error : HttpErrorResponse) => {
          this.toast.showError(error.error);
          return throwError(error);
        })        
      )
  }

  // Création d'une étiquette
  public postEtiquette(etiquette : Etiquette, nombre: number, quantite: number) : Observable<any> {    
    return this.http.post(this.urlApi+'/adela/etiquette/'+nombre + '/' + quantite, etiquette, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} });
  }

  // Modification d'une étiquette
  public putEtiquette(id: number, nombre: number, quantite: number, etiquette : Etiquette) : Observable<any> {
    return this.http.put(this.urlApi+'/adela/etiquette/'+id+'/'+nombre+'/'+quantite, etiquette, { responseType: "blob", headers: {'Content-Type': 'application/json' , Accept: "application/pdf"} });
  }

  //Récupération de la liste des étiquettes avec informations de dossier:
  public getEtiquettesDossier(idEntite:number=0):Observable<Etiquette[]>{
    return this.http.get<Etiquette[]>(this.urlApi+'/adela/etiquettes/'+idEntite)
  }
  //Récupération du prochain numéro d'étiquette disponible pour l'entité:
  public getNextAvailableEtiquette(idEntite:number):Observable<Etiquette[]>{
    return this.http.get<Etiquette[]>(this.urlApi+'/adela/etiquette/prochaine/'+idEntite)
  }
  // Téléchargement de la demande d'analyse
  public getDossierTelecharger(id: any): Observable<any> {
    return this.http.get(this.urlApi + '/adela/telechargement-confirmation?id='+id, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/pdf"} })
  }

  // Liste de l'historique du dossier
  public getHistoriqueDossier(id: any): Observable<HistoriqueDossier[]> {
    return this.http.get<HistoriqueDossier[]>(this.urlApi + '/adela/historique?'+this.CONST_PARAM_ID+'='+id)
  }
  // upload des pieces jointe lors de la demande de dossier
  public envoiPieceJointe(idDossier: number, idEntite: number, monFichier: Fichier): Observable<any> {
    return this.http.post<Fichier>(this.urlApi+'/adela/piecejointe/'+idDossier+'/'+idEntite, monFichier, { headers: {'Content-Type': 'application/json', Accept: "*/*"} })
  }

  public downloadPieceJointe(idEntite :number,idDossier: number,nomPJ: string):Observable<any>{
    return this.http.get(this.urlApi+'/adela/telechargement-pj/' + idEntite + '/' + idDossier + '/' + nomPJ, { responseType: "blob", headers: {'Content-Type': 'application/json', Accept: "application/octet-stream,application/pdf,image/png,image/jpeg"} });
  }

  //Chargement de la séquence
  public getSequence():Observable<any>{
    return this.http.get(this.urlApi+'/adela/sequence/etiquette')
  }

  public putSequence(sequence:Sequence):Observable<any>{
    return this.http.put(this.urlApi+'/adela/sequence/etiquette',sequence)
  }

  // Points d'entrée pour la gestion des valeurs de référence
  public putValeurReferences(tabValeurReference:ValeurReference[]):Observable<any> {
    return this.http.put(this.urlApi+'/adela/valeursreferences', tabValeurReference)
  }

  public getValeurReferences(dateDesValeurs : Date | undefined):Observable<ValeurReference[]> {
    
    let myDate : Date;

    if (dateDesValeurs == undefined) {
      return this.http.get<ValeurReference[]>(this.urlApi+'/adela/valeursreferences')
    } else {
      myDate = new Date(dateDesValeurs);

      return this.http.get<ValeurReference[]>(this.urlApi+'/adela/valeursreferences?date='+( myDate.getTime() / 1000 ))
    }
  }

  public getIconeName(code: string) {
    switch (code) {
      case this.CONST_ETAT_ADELA_1_Depose:
        return 'Déposé';
        break;
      case this.CONST_ETAT_ADELA_2_Valide:
        return 'Validé';
        break;
      case this.CONST_ETAT_ADELA_3_EnAttenteEchantillon:
        return 'En attente de l\'échantillon';
        break;
      case this.CONST_ETAT_ADELA_4_EnCoursAnalyse:
        return 'En cours d\'analyse';
        break;
      case this.CONST_ETAT_ADELA_5_EnCoursDiagnostic:
        return 'En cours de diagnostics';
        break;
      case this.CONST_ETAT_ADELA_6_Cloture:
        return 'Clôturé';
        break;
      case this.CONST_ETAT_ADELA_X_Refuse:
        return 'Refusé';
        break;
      default: 
        return ''
    }
  }

  public getColorByStatus(code: string) {
    switch(code) {
      case this.CONST_ETAT_ADELA_1_Depose:
        return '#e48213';
        break;
      case this.CONST_ETAT_ADELA_2_Valide:				        
        return '#04970E';
        break;
      case this.CONST_ETAT_ADELA_3_EnAttenteEchantillon:	
        return 'rgb(39,58,130)';
        break;
      case this.CONST_ETAT_ADELA_4_EnCoursAnalyse:		    
        return '#e48213';
        break;
      case this.CONST_ETAT_ADELA_5_EnCoursDiagnostic:	  
        return '#e48213';
        break;
      case this.CONST_ETAT_ADELA_6_Cloture:				      
        return 'rgb(39,58,130)';
        break;
      case this.CONST_ETAT_ADELA_X_Refuse:		            
        return '#f44336';
        break;	
      default:
          return 'rgb(39,58,130)';
          break;
    }
  }

  public getIcone(code: string) {
    switch (code) {
      case this.CONST_ETAT_ADELA_1_Depose:
        return 'folder';
        break;
      case this.CONST_ETAT_ADELA_2_Valide:
        return 'folder_special';
        break;
      case this.CONST_ETAT_ADELA_3_EnAttenteEchantillon:
        return 'invert_colors';
        break;
      case this.CONST_ETAT_ADELA_4_EnCoursAnalyse:
        return 'crop_free';
        break;
      case this.CONST_ETAT_ADELA_5_EnCoursDiagnostic:
        return 'filter_center_focus';
        break;
      case this.CONST_ETAT_ADELA_6_Cloture:
        return 'done';
        break;
      case this.CONST_ETAT_ADELA_X_Refuse:
        return 'close';
        break;
      default: 
        // return'file'
        return''
    }
  }

  downloadDossier(id: any, reference: any) {
    this.getDossierTelecharger(id)
      .subscribe(
        data => {

          if(data != undefined) {
            saveAs(data, `Confirmation-ADELA.pdf`);
          this.toast.showInfo('Confirmation de la demande d\'analyse téléchargé')
          }          
        },
        err => {    
          this.toast.showError('Problème rencontré lors du téléchargement de la demande d\'analyse.');     
          console.error('/!\ err: ' + err.message); 
        },
      ); 
  }

  dialogHistoriqueDossier(id: any) {
    const dialogRef = this.dialog.open(DialogHistoriqueDossierComponent, { panelClass: 'custom-dialog-container' });
    dialogRef.componentInstance.id = id
  }

  public onApercuRapport(idDossier: number, habilitation: string) { 
    

    this.getTelechargerResultatAnalyse(idDossier)
    .subscribe(
      (data) => {
        saveAs(data, `CLOTURE_`+habilitation+`_`+idDossier.toString()+`.pdf`);
        this.toast.showInfo("Téléchargement effectué");
        
      },
      (err) => {
        console.error('/!\\ error getTelechargerResultatAnalyse: '+err);
    
      }
    );
  }

  public openFile(dossier: any) {
    
    
    switch (dossier.prestation.typeElement.code) {
      case 1:
        this.router.navigate( ['app/adela/dossier/' , dossier.idDossier])
        break;
      
      case 2:
        this.router.navigate( ['app/adela-poudre/dossier/' , dossier.idDossier])
        break;

      default:
        break;
    }
  }

  getImmatNonFormated(immat:string){
    const s = immat
    return s.replace(/\W|_/g,'')
  }

  public postPrestation(prestation : Analyse):Observable<Analyse>{
    return this.http.post<Analyse>(this.urlApi+'/adela/prestation', prestation)
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `Une erreur est survenue : ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Le Backend a retourné ce code-erreur :  ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }
  
  verificationFormatNumEtiquette(fcControler: FormControl): any {
    let numEtiquette  : string = fcControler.value;
    let a_regex       : any = /^[0-9]{7,24}$/;
    
    if (!a_regex.test(numEtiquette)) {      
      return { erreur: ' L\'étiquette doit avoir entre 7 et 25 chiffres.' }
    }

    return null;
  }
}

