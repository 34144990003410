import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { Analyse, AnalyseInitiale } from 'src/app/interfaces/adela/analyse';
import { ListeElements } from 'src/app/interfaces/adela/liste-elements';
import { ListeTypeAffaire } from 'src/app/interfaces/adela/liste-type-affaire';
import { TypeElement } from 'src/app/interfaces/adela/resultat-analyse';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { ApiAdelaService } from 'src/app/services/api-adela.service';

@Component({
    selector: 'app-edit-prestation',
    templateUrl: './edit-prestation.component.html',
    styleUrls: ['./edit-prestation.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatSlideToggleModule, NgIf, MatSelectModule, NgFor, MatOptionModule, MatCheckboxModule, MatButtonModule, AsyncPipe]
})
export class EditPrestationComponent implements OnInit {

  @Input() prestation?     : any
  @Input() fluides$       : Observable<ListeElements[]>     = new Observable<ListeElements[]>()
  @Input() organes$       : Observable<ListeElements[]>     = new Observable<ListeElements[]>()
  @Input() typeAnalyses$  : Observable<ListeTypeAffaire[]>  = new Observable<ListeTypeAffaire[]>()
  @Input() typeElements$  : Observable<TypeElement[]>       = new Observable<TypeElement[]>()
  @Output() prestationSaved = new EventEmitter<Analyse>()

  get TypeElement()               { return this.prestationForm.get('TypeElement'); }

  title : string = '';
  prestationForm : FormGroup = new FormGroup({});
  
  constructor(private _fb : FormBuilder,
              private ApiAdela : ApiAdelaService
              ) { }

  ngOnInit(): void {
    
/*     this.initialiseTitle()
    this.initialiseForm() */
    this.initialise();
  }

  private initialise() {

    this.prestationForm = this._fb.group({
      code            : ['', [Validators.maxLength(6)]],
      idTypeAnalyse   : [''],
      categorie       : ['', Validators.required],
      designation     : ['', Validators.required],
      idOrgane        : [''],
      idFluide        : [''],
      codeLaboratoire : ['',[Validators.maxLength(12), Validators.required]],
      actif           : [''],
      tarif           : ['', Validators.required], 
      TypeElement     : ['',Validators.required],
      libelle         : ['',Validators.required],
      affiche         : ['']
    },
    {
      updateOn: 'blur',
    })

    if (this.prestation == undefined || (this.prestation?.id == 0 && (this.prestation?.duplicated == undefined || this.prestation?.duplicated === false )) ) {
      this.title = "Création d'une prestation";
      
      this.typeElements$.subscribe(
        (data: TypeElement[]) => {
          // analyse de fluide par défaut
          let typeElement : TypeElement | undefined = data.find(unTypeElement => unTypeElement.code == this.ApiAdela.CONST_TYPE_ELEMENT_FLUIDE);
          
          if (typeElement != undefined) {
            this.prestationForm.controls.TypeElement.setValue(typeElement);
            this.initTypeElement(typeElement); 
          }
        })

    } else 
    {
      this.prestationForm.patchValue(
        {
          code            : this.prestation?.code,
          idTypeAnalyse   : this.prestation?.idTypeAnalyse > 0 ? this.prestation?.idTypeAnalyse : '',
          categorie       : this.prestation?.categorie,
          designation     : this.prestation?.designation,
          idOrgane        : this.prestation?.idOrgane > 0 ? this.prestation?.idOrgane : '',
          idFluide        : this.prestation?.idFluide > 0 ? this.prestation?.idFluide : '',
          codeLaboratoire : this.prestation?.codeLaboratoire,
          actif           : this.prestation?.actif,
          tarif           : this.prestation?.tarif,
          TypeElement     : 0,
          libelle         : this.prestation?.libelle,
          affiche         : this.prestation?.affiche
        }
      )

      this.typeElements$.subscribe(
        (data: TypeElement[]) => {
          let typeElement : TypeElement | undefined = data.find(unTypeElement => unTypeElement.id == this.prestation?.idTypeElement);
          
          if (typeElement != undefined) {
            this.prestationForm.controls.TypeElement.setValue(typeElement);
            this.initTypeElement(typeElement); 
          }
        })

        this.prestationForm.markAllAsTouched();
      
      if (this.prestation?.duplicated===true) {
        this.title = "Duplication de la prestation " + this.prestation.designation  
      } else {
        this.title = "Edition de la prestation " + this.prestation?.designation 
      }
    }
  } 

  save(){   
    
    let analyse: Analyse = AnalyseInitiale;

    analyse.actif           = this.prestationForm.controls.actif.value;
    analyse.affiche         = this.prestationForm.controls.affiche.value;
    analyse.categorie       = this.prestationForm.controls.categorie.value;
    analyse.code            = this.prestationForm.controls.code.value;
    analyse.codeLaboratoire = this.prestationForm.controls.codeLaboratoire.value;
    analyse.designation     = this.prestationForm.controls.designation.value;
    if (this.prestationForm.controls.TypeElement.value.code == 1) {
      // analyse de fluide
      analyse.fluide        = this.prestation?.fluide;
      analyse.idFluide      = this.prestationForm.controls.idFluide.value;  
      analyse.idOrgane      = this.prestationForm.controls.idOrgane.value;
      analyse.idTypeAnalyse = this.prestationForm.controls.idTypeAnalyse.value;
      analyse.organe        = this.prestation?.organe;
    } else {
            // analyse de poudre
            analyse.fluide        = ''
            analyse.idFluide      = 0
            analyse.idOrgane      = 0
            analyse.idTypeAnalyse = 0
            analyse.organe        = '';
    }
    analyse.id              = this.prestation? this.prestation.id : 0;
    analyse.idTypeElement   = this.prestationForm.controls.TypeElement.value.id;
    analyse.libelle         = this.prestationForm.controls.libelle.value;
    analyse.tarif           = this.prestationForm.controls.tarif.value;
    
    this.prestationSaved.next(analyse);
  }


  cancel(){
    this.prestationSaved.next()
  }

  private initTypeElement(typeElement: TypeElement) : void {
    if (typeElement.code == this.ApiAdela.CONST_TYPE_ELEMENT_POUDRE) {
      // analyse de poudre
      this.prestationForm.controls.idTypeAnalyse.clearValidators();
      this.prestationForm.controls.idFluide.clearValidators();
      this.prestationForm.controls.idOrgane.clearValidators();
    } else {
      // analyse de fluide
      // code = 1
      this.prestationForm.controls.idTypeAnalyse.setValidators([Validators.required]);
      this.prestationForm.controls.idFluide.setValidators([Validators.required]);
      this.prestationForm.controls.idOrgane.setValidators([Validators.required]);
    }
    
    this.prestationForm.controls.idTypeAnalyse.updateValueAndValidity();
    this.prestationForm.controls.idFluide.updateValueAndValidity();
    this.prestationForm.controls.idOrgane.updateValueAndValidity();
  }

  onSelectChangeTypeElement(newSelect: any): void {
    this.initTypeElement(newSelect.value);
  }

}
