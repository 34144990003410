<fieldset class="cadre">
    <legend class="legend">{{label}}</legend>
    <div [formGroupName]="controlKey">
            <div [ngClass]="AlignmentClass">
                <mat-form-field>
                    <mat-label>Adresse</mat-label>
                    <input 
                        matInput 
                        type                = "text" 
                        formControlName     = "adresseLigne1"
                        maxlength           = "250" 
                        [matAutocomplete]   = "auto"
                        (keyup)             = "getAdresse($event)"
                    >
                        <mat-autocomplete #auto = "matAutocomplete">
                                <mat-option
                                    *ngFor      = "let adresse of adresses$|async"
                                    [value]     = "adresse.properties.name"
                                    (onSelectionChange)="optionSelectionChange(
                                            adresse
                                    )"
                                >
                                {{adresse.properties.label}}
                                </mat-option>
                        </mat-autocomplete>
                    <!-- <mat-error *ngIf="adresseLigne1?.invalid && (adresseLigne1?.dirty || adresseLigne1?.touched)">{{ adresseLigne1?.errors?.erreur }}</mat-error> -->
                    <mat-error *ngIf="adresseLigne1?.hasError('required')">L'adresse est obligatoire</mat-error>
                </mat-form-field>
        
                <mat-form-field *ngIf="adresse.ligne2!=undefined">
                    <mat-label>Complément</mat-label>
                    <input matInput 
                        type            = "text"  
                        formControlName = "adresseLigne2"
                        maxlength       = "250"
                        placeholder     ="N° appartement, boite aux lettres, étage, couloir" 
                        >
                </mat-form-field>

                <mat-form-field *ngIf="adresse.ligne3!=undefined">
                    <mat-label>Complément</mat-label>
                    <input matInput 
                        type            = "text"  
                        formControlName = "adresseLigne3"
                        maxlength       = "250"
                        placeholder     = "entrée, tour, bâtiment, immeuble, résidence" 
                        >
                </mat-form-field>
            </div>
            <div [ngClass]="AlignmentClass">
                <mat-form-field>
                    <mat-label>Code Postal</mat-label>
                    <input matInput 
                        type                = "text"
                        oninput             = "this.value=this.value.replace(/[^0-9]/g,'');"
                        formControlName     = "adresseCP" 
                        minlength           = "5"
                        maxlength           = "5"
                        >
                    <mat-error *ngIf="adresseCP?.hasError('required')">Le code postal est obligatoire</mat-error>
                    <mat-error *ngIf="adresseCP?.hasError('maxlength')">Le code postal doit être sur cinq caractères</mat-error>
                    <mat-error *ngIf="adresseCP?.hasError('minlength')">Le code postal doit être sur cinq caractères</mat-error>
                </mat-form-field>
                
                <mat-form-field >
                    <mat-label>Ville</mat-label>
                    <input matInput 
                        type                = "text" 
                        formControlName     = "adresseVille"
                        maxlength           = "100" 
                        oninput             = "this.value = this.value.toUpperCase()" 
                        >
                    <mat-error *ngIf="adresseVille?.hasError('required')">La ville est obligatoire</mat-error>
                </mat-form-field>
            </div>
    </div>
</fieldset>