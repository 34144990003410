import { CurrencyPipe } from '@angular/common';
import { Component, computed, effect, Inject, signal, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Analyse } from 'src/app/interfaces/adela/analyse';

@Component({
  selector: 'app-analyse-adela-selection',
  templateUrl: './analyse-adela-selection.component.html',
  styleUrls: ['./analyse-adela-selection.component.scss'],
  standalone:true,
  imports:[
    MatTableModule, MatDialogModule, MatButtonModule,MatIconModule,MatTooltipModule,MatFormFieldModule,MatInputModule]
  ,encapsulation: ViewEncapsulation.None // Désactive l'encapsulation pour appliquer les styles globalement
})
export class AnalyseAdelaSelectionComponent {

  displayedColumns: string[] = ['categorie', 'designation', 'typeAnalyse', 'fluide', 'Organe', 'tarif', 'select'];
  dataSource: MatTableDataSource<Analyse>;
  selectedAnalyse: Analyse | null = null;

  // Signal pour stocker la valeur du filtre
  filterText = signal<string>('');

  // Liste des analyses reçue
  analyses = signal<Analyse[]>([]);

  // Liste filtrée calculée dynamiquement
  filteredAnalyses = computed(() =>
    this.analyses().filter((analyse) =>
      this.filterText()
        ? analyse.codeLaboratoire.toUpperCase().includes(this.filterText())
        : true
    )
  );
  
  constructor(
    public dialogRef: MatDialogRef<AnalyseAdelaSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      analyses: Analyse[],
      getTypeFluide: (idFluide: number) => string,
      getOrganePrelevement: (idOrgane: number) => string,
      getTypeAnalyse: (idTypeAnalyse: number) => string,
      analyseRetenue:Analyse
    }
  ) {
    this.dataSource = new MatTableDataSource(data.analyses);
    this.selectedAnalyse = data.analyseRetenue || null;
    // Initialisation des analyses avec les données reçues
    this.analyses.set(data.analyses);

  }

  onFilterInput(value: string): void {
    // Convertir la saisie utilisateur en majuscules
    this.filterText.set(value.toUpperCase());
  }

  onSelect(analysis: Analyse) {
    this.dialogRef.close(analysis);
  }

  onCancel() {
    this.dialogRef.close();
  }  

  isSelected(analyse: Analyse): boolean |null {
    return this.selectedAnalyse && this.selectedAnalyse.id === analyse.id;
  }
}
