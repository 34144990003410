import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Contenu, Operation } from '../../interfaces/contenu';
import { BaliseMail } from '../../interfaces/balise';
import { EMPTY, Observable } from 'rxjs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { ListBaliseComponent } from '../list-balise/list-balise.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { CKEditorComponent, CKEditorModule } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CkeditorService } from 'src/app/services/ckeditor.service';

@Component({
    selector: 'app-edit-email',
    templateUrl: './edit-email.component.html',
    styleUrls: ['./edit-email.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule
              , ListBaliseComponent, CKEditorModule, MatButtonModule, MatProgressSpinnerModule]
})
export class EditEmailComponent implements OnInit {

  @Input()  operationsLeft!   : Operation[]
  @Input()  mail!             : Contenu
  @Input()  balises$          : Observable<BaliseMail[]> = EMPTY
  @Input()  waiting           : boolean = false
  @Output() mailEvent         = new EventEmitter<Contenu | null>()
  @ViewChild('editor', { static: false }) editorComponent! : CKEditorComponent
  
  public editorContent = ''; 
  
  public getEditor() {
    // Warning: This may return "undefined" if the editor is hidden behind the `*ngIf` directive or
    // if the editor is not fully initialised yet.
    return this.editorComponent.editorInstance;
}
  public Editor = ClassicEditor;
  
  cursorPosition              : number = 0
  operationsSaved             : Operation[] = []
  get operationsForm()    { return this.formMail.get('operations') }
  public editorConfig 
   
  
  formMail! : FormGroup
  
  constructor(private _fb:FormBuilder, private apiCkEditor: CkeditorService) {
    this.editorConfig = {...this.apiCkEditor.editorConfig}
   }

  ngOnInit(): void {
    this.initFormMail()
    this.setMailOperations()
   console.log(this.editorConfig);
   
    
  }

  initFormMail(){
    this.formMail = this._fb.group(
        {
          note        : [this.mail.note,Validators.required],
          sujet       : [this.mail.sujet, Validators.required],
          contenu     : [this.mail.contenu,Validators.required],
          operations  : [Array<Operation>()]
        }
      )
  }

  save(){
    const mailSaved:Contenu = {...this.mail,...this.formMail.value}
    this.mailEvent.next(mailSaved)
  }

  cancel(){
    this.mailEvent.next(null)
  }

  setMailOperations(){
    const operationsId    : number[]    = this.mail.operations.map((operation:Operation)=> {return operation.id})
    const operationsMail  : Operation[] = this.operationsLeft.filter((operation)=> operationsId.includes(operation.id))
    
    this.formMail.controls.operations.setValue(operationsMail)
    
  }

  getBalise(balise : BaliseMail){
   
    const selection = this.getEditor()?.model.document.selection
    const range = selection?.getFirstRange()
    
    if (range) {

      this.getEditor()?.model.change(writer => {
        writer.insertText(balise.code, range.start);
      });
    }
  }

  addStyle(){
    // this.editorConfig.stylesSet.add({ name: 'Balise: Green', element: 'span', styles: { 'background-color': 'Green' } })
  }

}
