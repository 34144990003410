import { Component, OnInit, Output, Input, EventEmitter, ViewChild }    from '@angular/core';
import { Categorie }            from 'src/app/interfaces/phrasier/categorie';
import { Phrasier }             from 'src/app/interfaces/phrasier/phrasier';
import { SousCategorie }        from 'src/app/interfaces/phrasier/sous-categorie';
import { ApiPhrasierService }   from 'src/app/services/api-phrasier.service';
import { PersoSnackbarService } from 'src/app/services/perso-snackbar.service';
import { FormBuilder, ReactiveFormsModule, Validators, FormControl }          from '@angular/forms';
import { MatDialog }            from '@angular/material/dialog';
import { DialogConfirmationSuppressionComponent } from '../dialog-confirmation-suppression/dialog-confirmation-suppression.component';
import { Clipboard }            from '@angular/cdk/clipboard';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTableResponsiveLargeDirective } from '../../../directive/mat-table-responsive-large/mat-table-responsive-large.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { FormPhrasierComponent } from '../form-phrasier/form-phrasier.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAdelaService } from 'src/app/services/api-adela.service';
import { TypeElement } from 'src/app/interfaces/adela/resultat-analyse';

@Component({
    selector: 'app-phrasier',
    templateUrl: './phrasier.component.html',
    styleUrls: ['./phrasier.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatExpansionModule, MatIconModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, MatOptionModule, NgFor, NgIf,
              MatButtonModule, MatProgressSpinnerModule, MatTableModule, MatTableResponsiveLargeDirective, MatPaginatorModule, FormPhrasierComponent]
})
export class PhrasierComponent implements OnInit {

  // Gestion de l'affichage
  waitingResearch       : boolean               = false;
  ExpandPannel          : boolean               = false;
  categoriesFiltre      : Array<Categorie>      = new Array<Categorie>();
  sous_categoriesFiltre : Array<SousCategorie>  = new Array<SousCategorie>();
  editMode              : boolean               = false; 

  // Gestion de la pagination de la table
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | null = null;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(paginator: MatPaginator) {this.dataSource.paginator = paginator;}
  // Variables pour gérer l'envoi/réception avec l'apiPhrasier
  phrasier              : Phrasier              = <Phrasier>{};
  phrasiers             : Array<Phrasier>       = new Array<Phrasier>();
  categories            : Array<Categorie>      = new Array<Categorie>();
  sous_categories       : Array<SousCategorie>  = new Array<SousCategorie>();
  typesElements         : Array<TypeElement>    = new Array<TypeElement>();

  // Variable utilisé pour le formulaire de Filtres
  formGroupFiltre = this.fBuilder.group({
    categorie     : [<Categorie>{}],
    sous_categorie: [<SousCategorie>{}],
    typeElement   : new FormControl<TypeElement|null>(null, Validators.required)
  });

  // Tableau des phrasiers
  displayedColumns: string[] = ['categorie', 'sous-categorie', 'titre', 'phrase', 'coller', 'modifier', 'supprimer'];
  dataSource      : MatTableDataSource<Phrasier>;

  // Variable utilisé pour copier le(s) phrasier(s) vers le diagnostic de l'analyse des résultats ADELA
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() showAllTable : boolean = true;
  @Input()  defaultTypeElementCode : number = 0;
  
  constructor(  private apiPhrasier : ApiPhrasierService
              , private fBuilder    : FormBuilder
              , private toast       : PersoSnackbarService
              , public dialog       : MatDialog
              , private clipboard   : Clipboard
              , private route       : Router
              , private activatedRoute  : ActivatedRoute
              , private apiAdela    : ApiAdelaService) { 
    //Initialisation de la source de données de la table
    this.dataSource   = new MatTableDataSource(); 
  }

  ngOnInit(): void {   
    this.getTypesElement();
    this.getCategories();        
    // this.getPhrasiers();
    
    this.formGroupFiltre.updateValueAndValidity();

    this.activatedRoute.url.subscribe(url => {
      let currentElement : string;

      const elements    = this.route.url.split('/');
      currentElement = elements[elements.length - 1];
      
      switch (currentElement) {       
        case 'phrasier':
          this.editMode  = false;
          break;
        
        case 'edit':
          this.editMode  = true;
          break;

        default:
          break;
      }
    })
  }

/*   ngOnChanges(): void {
    this.getCategories();        
    this.getPhrasiers();
  } */

  onCopyContentPhrasier(phrase: string) {
    this.newItemEvent.emit(phrase);
    this.clipboard.copy(phrase);
    this.toast.showInfo("Phrasier copié")
  }

  onCreerPhrasier() { 
    this.editMode = true;
    this.phrasier = <Phrasier>{id:0, categorie: {id:0}, sous_categorie: {id:0} };
    this.route.navigate(['app/phrasier/edit'])
  }

  onModifierPhrasier(phrasier: Phrasier) {
    this.phrasier = phrasier;
    this.editMode = true;
    this.route.navigate(['app/phrasier/edit'])
  }

  onSupprimerPhrasier(phrasier: Phrasier) {    
    if(phrasier != undefined) {
      if(phrasier.id > 0) {        
        const dialogRef = this.dialog.open(DialogConfirmationSuppressionComponent);
        dialogRef.componentInstance.id = phrasier.id;
        dialogRef.afterClosed()
        .subscribe(
          (data: boolean) => { 
            if(data) {
              this.getPhrasiers();
            }
          },
          (err: any) => { 
            '/!\\ error dialogConfirmationSuppression: ' + console.log(err);
            this.waitingResearch = false;
          },
          () => { console.log('complete: dialogConfirmationSuppression') }
        );
      } else {
        this.toast.showError("Aucun phrasier sélectionné.");
      }
    } else {
      this.toast.showError("Une erreur est survenue, si l'erreur persiste veuillez contacter le support.");
    }
  }

  onSelectChangeCategorie(newSelect: any) {  
    this.sous_categoriesFiltre = this.sous_categories;

    if(newSelect != undefined) {
      if(newSelect.value != undefined) {
        if(newSelect.value.id > 0) {
          this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === newSelect.value.id || item.id_categorie === 0);         
        } else {
          this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);
        }      
      } else {
        this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);  
      }      
    } else {
      this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie === 0);
    }       
  }
  
  onSelectChangeTypeElement(newSelect : MatSelectChange) {
    this.categoriesFiltre = this.categories;
    this.sous_categoriesFiltre = this.sous_categories.filter(item => item.id_categorie === 0);
    
    if(newSelect != undefined) {
      if(newSelect.value != undefined) {
        if(newSelect.value.id > 0) {
          this.categoriesFiltre = this.categoriesFiltre.filter(item => item.idTypeElement === newSelect.value.id);         
        } else {
          this.categoriesFiltre = this.categoriesFiltre.filter(item => item.idTypeElement === 0);
        }      
      } else {
        this.categoriesFiltre = this.categoriesFiltre.filter(item => item.idTypeElement === 0);  
      }      
    } else {
      this.categoriesFiltre = this.categoriesFiltre.filter(item => item.idTypeElement === 0);
    }   
  }

  // Chargements des phrasiers
  public getPhrasiers() : void {    
    let idCategorie     : number = 0;
    let idSousCategorie : number = 0;
    let idTypeElement   : number = 0;

    if(this.formGroupFiltre.controls.categorie.value != undefined) {
      idCategorie = this.formGroupFiltre.controls.categorie.value.id;
    }

    if(this.formGroupFiltre.controls.sous_categorie.value) {
      idSousCategorie = this.formGroupFiltre.controls.sous_categorie.value.id;
    }

    if(this.formGroupFiltre.controls.typeElement.value != null) {
      idTypeElement = this.formGroupFiltre.controls.typeElement.value.id;
    }

    this.waitingResearch = true;
    this.apiPhrasier.getPhrasiers(idCategorie, idSousCategorie, idTypeElement)
      .subscribe(
        (data: Phrasier[]) => { 
          this.phrasiers = data;
          this.dataSource.data = this.phrasiers;
          this.waitingResearch = false;
        },
        (err: any) => { 
          '/!\\ error getPhrasiers: ' + console.log(err);
          this.waitingResearch = false;
        }
      );
  }

  // Chargement des catégories
  public getCategories() : void {
    this.apiPhrasier.getCategories()
      .subscribe(
        (data: Categorie[]) => {
          
          this.categories = new Array<Categorie>();
          this.categories = [ <Categorie>{id : 0, libelle: 'Toutes', idTypeElement: this.apiAdela.CONST_TYPE_ELEMENT_FLUIDE}, <Categorie>{id : 0, libelle: 'Toutes', idTypeElement: this.apiAdela.CONST_TYPE_ELEMENT_POUDRE} , ...data ];

          this.getSousCategories();
        },
        (err: any) => { '/!\\ error getCategories: ' + console.log(err) }
      );
  }

  // Chargement des sous-catégories
  public getSousCategories() : void {
    this.apiPhrasier.getSousCategories()
      .subscribe(
        (data: SousCategorie[]) => {
          
          this.sous_categories = new Array<SousCategorie>();
          this.sous_categories = [<SousCategorie>{id: 0, id_categorie: 0, libelle : 'Toutes'}, ...data]

          this.sous_categoriesFiltre = this.sous_categories;
          const id = this.formGroupFiltre.controls.categorie.value? this.formGroupFiltre.controls.categorie.value.id:0
          this.onSelectChangeCategorie(id);          
        },
        (err: any) => { '/!\\ error getSousCategories: ' + console.log(err) }
      );
  }

  public getTypesElement(): void {
    this.apiAdela.getTypeElement().subscribe(
      (typesElements)=> {
        this.typesElements = typesElements;
        
        if (this.defaultTypeElementCode > 0 ) {
          this.formGroupFiltre.patchValue({
            typeElement : this.typesElements.find(element => element.code == this.defaultTypeElementCode)
          })

          this.formGroupFiltre.updateValueAndValidity();
        }
      }
    )
  }

  public closeEditMode(close : boolean) {
    this.editMode = !close;
    this.route.navigate(['app/phrasier'])
  }

  public addPhrasier(phrasier: Phrasier) {
    this.dataSource.data.push(phrasier);
  }
}
