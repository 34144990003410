import { Injectable } from '@angular/core';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';


@Injectable({
  providedIn: 'root'
})
export class CkeditorService {
  public editorConfig = {
    toolbar: [
      'undo', 'redo',
      '|',
      'heading',
      '|',
      'bold', 'italic', 'underline', 'strikethrough',
      '|',
      'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
      '|',
      'alignment',
      '|',
      'numberedList', 'bulletedList',
      '|',
      'indent', 'outdent',
      '|',
      'link', 'blockQuote', 'insertTable', 'imageUpload',
      '|',
      'removeFormat',
    ],
    fontFamily: {
      options: [
        'default',
        'Arial, Helvetica, sans-serif',
        'Courier New, Courier, monospace',
        'Georgia, serif',
        'Lucida Sans Unicode, Lucida Grande, sans-serif',
        'Tahoma, Geneva, sans-serif',
        'Times New Roman, Times, serif',
        'Verdana, Geneva, sans-serif'
      ]
    },
    fontSize: {
      options: [
        'tiny',
        'small',
        'default',
        'big',
        'huge'
      ]
    },
    fontColor: {
      colors: [
        {
          color: 'hsl(0, 0%, 0%)',
          label: 'Black'
        },
        {
          color: 'hsl(0, 0%, 30%)',
          label: 'Dim grey'
        },
        {
          color: 'hsl(0, 0%, 60%)',
          label: 'Grey'
        },
        {
          color: 'hsl(0, 0%, 90%)',
          label: 'Light grey'
        },
        {
          color: 'hsl(0, 0%, 100%)',
          label: 'White',
          hasBorder: true
        }
      ]
    },
    fontBackgroundColor: {
      colors: [
        {
          color: 'hsl(120, 75%, 60%)',
          label: 'Green'
        },
        {
          color: 'hsl(60, 75%, 60%)',
          label: 'Yellow'
        },
        {
          color: 'hsl(0, 75%, 60%)',
          label: 'Red'
        },
        {
          color: 'hsl(30, 75%, 60%)',
          label: 'Orange'
        },
        {
          color: 'hsl(90, 75%, 60%)',
          label: 'Light green'
        }
      ]
    },
    language: 'fr',
  };
  

  
  constructor() { }
}