import { Component, OnInit, Input, Output, EventEmitter }                    from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Categorie }                            from 'src/app/interfaces/phrasier/categorie';
import { Phrasier }                             from 'src/app/interfaces/phrasier/phrasier';
import { SousCategorie }                        from 'src/app/interfaces/phrasier/sous-categorie';
import { ApiPhrasierService }                   from 'src/app/services/api-phrasier.service';
import { PersoSnackbarService }                 from 'src/app/services/perso-snackbar.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule, MatSelectChange } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TypeElement } from 'src/app/interfaces/adela/resultat-analyse';

@Component({
    selector: 'app-form-phrasier',
    templateUrl: './form-phrasier.component.html',
    styleUrls: ['./form-phrasier.component.scss'],
    standalone: true,
    imports: [MatCardModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, MatInputModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule]
})
export class FormPhrasierComponent implements OnInit {

  // Constantes utilisées pour afficher/masquer la nouvelle catégorie/sous-caégorie
  const_form_new_categorie        = 999999 as const; 
  const_form_new_sous_categorie   = 999998 as const; 

  get titre()               { return this.formGroupPhrasier.get('titre'); }
  get phrase()              { return this.formGroupPhrasier.get('phrase'); }
  get categorie()           { return this.formGroupPhrasier.get('categorie'); }
  get new_categorie()       { return this.formGroupPhrasier.get('new_categorie'); }
  get sous_categorie()      { return this.formGroupPhrasier.get('sous_categorie'); }
  get new_sous_categorie()  { return this.formGroupPhrasier.get('new_sous_categorie'); }

  // Variables pour gérer le phrasier (chargement, création, modification)
  // id                    : number                = 0;
  @Input()  phrasier     : Phrasier                = <Phrasier>{id:0, categorie: {id:0}, sous_categorie: {id:0} };
  @Input()  editMode     : Boolean                 = false;
  @Output() close = new EventEmitter<boolean>();
  @Output() add = new EventEmitter<Phrasier>();

  // Gestion de la création ou modification en affichage
  bModeCreation           : boolean               = false;
  bAfficheNewCategorie    : boolean               = false;
  bAfficheNewSousCategorie: boolean               = false;
  titre_page              : string                = '';
  @Input() categories     : Array<Categorie>      = new Array<Categorie>();
  @Input() sous_categories: Array<SousCategorie>  = new Array<SousCategorie>();
  @Input() typesElements  : Array<TypeElement>    = new Array<TypeElement>();
  sous_categoriesFiltre   : Array<SousCategorie>  = new Array<SousCategorie>();
  categoriesFiltre        : Array<Categorie>     = new Array<Categorie>();
  typeElementSelected!    : number;
  categorieSelected!      : Categorie;
  sousCategorieSelected!  : SousCategorie;
  waitingSave             : boolean               = false;

  // Variables utilisées pour le formulaire
formGroupPhrasier = this.fBuilder.group({
    new_categorie           : new FormControl('',   [Validators.required]),
    categorie               : new FormControl(0 , [Validators.required]),
    new_sous_categorie      : new FormControl('',   [Validators.required]),  
    sous_categorie          : new FormControl(0 , [Validators.required]),
    titre                   : new FormControl('',   [Validators.required]),
    phrase                  : new FormControl('',   [Validators.required]),
    typeElement             : new FormControl(0,   [Validators.required]),
  });

  constructor(  private apiPhrasier : ApiPhrasierService
              , private fBuilder    : FormBuilder
              , private toast       : PersoSnackbarService) { }

  ngOnInit(): void {
    
    // Chargement des catégories
    this.getCategories(this.phrasier.categorie.idTypeElement);
    this.getSousCategories();
    this.changeTypeElement(this.phrasier.categorie.idTypeElement)
    this.changeCategorie(this.phrasier.categorie)
    this.changeSousCategorie(this.phrasier.sous_categorie)
    
    this.formGroupPhrasier.patchValue({
      new_categorie       : '',
      categorie           : this.phrasier.categorie.id,
      new_sous_categorie  : '',
      sous_categorie      : this.phrasier.sous_categorie.id,
      titre               : this.phrasier.titre,
      phrase              : this.phrasier.phrase,
      typeElement         : this.phrasier.categorie.idTypeElement
    });
    this.formGroupPhrasier.updateValueAndValidity();
    
    if(this.phrasier.id > 0 ) {
      // Mode édition : on récupère le phrasier
      this.bModeCreation = false;
      this.titre_page = 'Modifier une phrase';      
    } else {
      // Mode création : on start from scratch
      this.titre_page = 'Créer une phrase';
      this.bModeCreation = true;      
    }    
  }

  public onSelectTypeElement(newSelect: MatSelectChange) {
    this.changeTypeElement(newSelect.value)
  }

  private changeTypeElement(idTypeElement : number) {
    if (idTypeElement > 0) {
      this.typeElementSelected = idTypeElement;

      this.getCategories(idTypeElement);
    }
  }

  public onSelectChangeCategorie(newSelect: MatSelectChange) {    
    let categorie : Categorie | undefined;
        
    categorie = this.categoriesFiltre.find((element) => ( element.id == newSelect.value ));
    this.changeCategorie(categorie)
  }

  private changeCategorie(categorie : Categorie | undefined) {
    if (categorie != undefined) {
      this.categorieSelected      = categorie;
      this.bAfficheNewCategorie   = false;
      
      // this.sous_categoriesFiltre  = this.sous_categories;
      this.getSousCategories();
  
      if(categorie.id != undefined) {
        if(categorie.id > 0) {
          this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id_categorie == categorie?.id || item.id == this.const_form_new_sous_categorie);
          if(categorie.id == this.const_form_new_categorie) {
            this.bAfficheNewCategorie = true;
          }               
        } else {
          this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id === this.const_form_new_sous_categorie);  
        }  
      } else {
        this.sous_categoriesFiltre = this.sous_categoriesFiltre.filter(item => item.id === this.const_form_new_sous_categorie);
      }
      
      if(this.bAfficheNewCategorie) {
        this.formGroupPhrasier.controls['new_categorie'].enable();
      } else {
        this.formGroupPhrasier.controls['new_categorie'].disable();
      }
    }
  }

  public onSelectChangeSousCategorie(newSelect: MatSelectChange) {
    let sousCategorie : SousCategorie | undefined;

    sousCategorie = this.sous_categoriesFiltre.find((element) => ( element.id == newSelect.value ));
    
    this.changeSousCategorie(sousCategorie)
  }

  private changeSousCategorie(sousCategorie : SousCategorie | undefined) {
    if ( sousCategorie != undefined ) {
      this.sousCategorieSelected = sousCategorie;

      this.bAfficheNewSousCategorie = false;
  
      if(this.sousCategorieSelected.id != undefined) {
        if(this.sousCategorieSelected.id == this.const_form_new_sous_categorie) {
          this.bAfficheNewSousCategorie = true;
        }      
      }
  
      if(this.bAfficheNewSousCategorie) {
        this.formGroupPhrasier.controls['new_sous_categorie'].enable();
      } else {
        this.formGroupPhrasier.controls['new_sous_categorie'].disable();
      }
    }
  }

  public onClickValider(){
    this.waitingSave = true;
    this.bindFormulairePhrasier()
    
    if(this.bModeCreation) {
      this.postPhrasier(this.phrasier);
    } else {
      this.putPhrasier(this.phrasier.id, this.phrasier);
    }
  }
  
  public onClickAnnuler(){
    this.close.next(true);
  }

  private bindFormulairePhrasier() {
    let id_categorie : number = 0;
  
    if(this.bModeCreation) {
      this.phrasier.id = 0;
    }

    if(this.categorieSelected.id == this.const_form_new_categorie) {
      // Si nouvelle catégorie : récupération dans le champ de saisie
      this.phrasier.categorie = {
        id      : 0,
        libelle : this.formGroupPhrasier.controls.new_categorie.value? this.formGroupPhrasier.controls.new_categorie.value:'',
        idTypeElement : this.typeElementSelected
      }
    } else {
      // Si catégorie déjà existante : récupération dans la combo
      this.phrasier.categorie = this.categorieSelected  
    }

    // Si nouvelle sous-catégorie
    if(this.sousCategorieSelected.id == this.const_form_new_sous_categorie){
      // Si nouvelle catégorie
      if(this.categorieSelected.id == this.const_form_new_categorie) {
        // On rattache la sous-catégorie à une nouvelle catégorie
        id_categorie = 0
      } else {
        // On rattache la sous-catégorie à une catégorie déjà existante
        id_categorie = this.categorieSelected.id; 
      }
      
      this.phrasier.sous_categorie = {
        id: 0,
        id_categorie  : id_categorie,
        libelle       : this.formGroupPhrasier.controls.new_sous_categorie.value? this.formGroupPhrasier.controls.new_sous_categorie.value : ''
      }

    } else {
      // On récupère dans la combo
      this.phrasier.sous_categorie = this.sousCategorieSelected;
    }

    this.phrasier.titre   = this.formGroupPhrasier.controls.titre.value? this.formGroupPhrasier.controls.titre.value:'';
    this.phrasier.phrase  = this.formGroupPhrasier.controls.phrase.value? this.formGroupPhrasier.controls.phrase.value :'';
  }


  // Création d'un phrasier
  private postPhrasier(phrasier: Phrasier) : void { 
    this.apiPhrasier.postPhrasier(phrasier)
      .subscribe(
        (data: Phrasier) => { 
          // this.phrasier = data;
          this.toast.showInfo("Phrasier créé")
          this.add.emit(data);
          this.waitingSave = false;
          this.close.next(true);
        },
        (err: any) => {
          '/!\\ error postPhrasier: ' + console.log(err);
          this.waitingSave = false;
        }
      );
  }

  // Modification d'un phrasier
  private putPhrasier(id: number, phrasier: Phrasier) : void {     
    this.apiPhrasier.putPhrasier(id, phrasier)
      .subscribe(
        (data: any) => { 
          this.toast.showInfo("Phrasier modifié");
          this.waitingSave = false;
          this.close.next(true);
        },
        (err: any) => {
          '/!\\ error putPhrasier: ' + console.log(err);
          this.waitingSave = false;
      }
      );
  }

  // Chargement des catégories
  private getCategories(idTypeElement : number) : void {

    this.categoriesFiltre = new Array<Categorie>();
    this.categoriesFiltre = this.categories.filter(categorie => categorie.idTypeElement == idTypeElement)
    
    let tmpCategorie : Categorie = {
      id      : this.const_form_new_categorie,
      libelle : 'Nouvelle catégorie',
      idTypeElement : idTypeElement
    };

    this.categoriesFiltre.push(tmpCategorie);          
  }

  // Chargement des sous-catégories
  private getSousCategories() : void {
    this.sous_categoriesFiltre = [];
    this.sous_categoriesFiltre = this.sous_categoriesFiltre.concat(this.sous_categories);

    let tmpSouscategorie : SousCategorie = {
      id            : this.const_form_new_sous_categorie,
      id_categorie  : 0,
      libelle       : 'Nouvelle Sous-catégorie'
    };

    this.sous_categoriesFiltre.push(tmpSouscategorie);
  }

  public autoGrowTextZone(event : any) {
    if (event.target.style.height != null && event.target.style.height != undefined) {
      event.target.style.height = "0px";
      event.target.style.height = (event.target.scrollHeight + 25)+"px";
    }
  }
}
